import React from 'react'

import { File } from 'features/common/types'

export interface AdaptiveImageProps {
  file: File | undefined
  [key: string]: string | number | object | undefined
}

const AdaptiveImage = ({ file, ...rest }: AdaptiveImageProps) => {
  if (!file) {
    return <img {...rest} alt={''} />
  }

  return (
    <img
      src={file?.formatted.sm}
      loading="lazy"
      srcSet={`${file?.formatted.sm} 400w, ${file?.formatted.md} 720w, ${file?.formatted.lg} 1280w, ${file?.url} 3200w`}
      {...rest}
      alt={''}
    />
  )
}

export default AdaptiveImage
