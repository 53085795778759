import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { Activity, City, Location, Vendor } from 'features/common/types'
import {
  getActivities,
  getActivityImg,
  getCities,
  getLocations,
  currencyConversion,
  getVendors,
} from 'slices/booking/api'
import { bookingActions } from 'slices/booking/store/booking.slice'

function* onGetActivities({
  payload,
}: {
  type: typeof bookingActions.getActivities
  payload: string | undefined
}): SagaIterator {
  try {
    const res: { activities: Activity[] } = yield call(getActivities, payload)
    yield put(bookingActions.getActivitiesSuccess(res.activities))
  } catch (e) {
    yield put(bookingActions.getActivityImgFailure(e))
  }
}

function* onGetActivityImg({
  payload,
}: {
  type: typeof bookingActions.getActivityImg
  payload: string
}): SagaIterator {
  try {
    const res: { activities: Activity[] } = yield call(getActivityImg, payload)
    const files = res.activities[0].files || []
    if (files) {
      yield put(bookingActions.getActivityImgSuccess(files))
    } else {
      yield put(bookingActions.getActivityImgFailure('No activity file fetched.'))
    }
  } catch (e) {
    yield put(bookingActions.getActivityImgFailure(e))
  }
}

function* onGetCityImg({
  payload,
}: {
  type: typeof bookingActions.getCityImg
  payload: string
}): SagaIterator {
  try {
    const res: { cities: City[] } = yield call(getCities, payload)
    const files = res.cities[0].files || []
    const file = files.length > 0 ? files[0] : undefined
    if (file) {
      yield put(bookingActions.getCityImgSuccess(file))
    } else {
      yield put(bookingActions.getCityImgFailure('No city file fetched.'))
    }
  } catch (e) {
    yield put(bookingActions.getCityImgFailure(e))
  }
}

function* onGetLocations({
  payload,
}: {
  type: typeof bookingActions.getLocations
  payload: { locationName: string; activityName: string }
}): SagaIterator {
  try {
    const res: { locations: Location[] } = yield call(getLocations, payload)
    yield put(bookingActions.getLocationsSuccess(res.locations))
  } catch (e) {
    yield put(bookingActions.getLocationsFailure(e))
  }
}

function* onGetVendors({
  payload,
}: {
  type: typeof bookingActions.getVendors
  payload: {
    city: string
    shortName: string
    selectedLocations: Location[]
    areas: Location[]
    selectedCurrencyCode: string | undefined
  }
}): SagaIterator {
  try {
    const res: Vendor[] = yield call(getVendors, payload)
    const vendors = res
      .filter((item: Vendor) => item.price && item.price.length > 0)
      .sort((item1: Vendor, item2: Vendor) => {
        if (item1.price && item2.price && item1.price.length > 0 && item2.price.length > 0) {
          return item1.price[0] - item2.price[0]
        }
        return -1
      })
    if (vendors.length === 0) {
      yield put(bookingActions.getVendorsSuccess([]))
      return
    }
    let rate = 1
    const { currency } = vendors[0]
    const currencyCode = currency && currency?.length > 0 ? currency[0].code : undefined
    if (payload.selectedCurrencyCode && currencyCode !== payload.selectedCurrencyCode) {
      const data = {
        base_currency_code: currencyCode || '',
        desired_currency_code: payload.selectedCurrencyCode || '',
      }
      const { conversion_rate: conversionRate } = yield call(currencyConversion, data)
      rate = conversionRate
    }
    const result = vendors.map(vendor => {
      const price = vendor?.price && vendor.price.length > 0 ? vendor.price[0] : 0
      return {
        ...vendor,
        formattedPrice: `${(price * rate).toFixed(2)} ${payload.selectedCurrencyCode}`,
        discountedPrice: `${(price * rate * 0.8).toFixed(2)} ${payload.selectedCurrencyCode}`,
      }
    })
    yield put(bookingActions.getVendorsSuccess(result))
  } catch (e) {
    yield put(bookingActions.getVendorsFailure(e))
  }
}

// Watcher Saga
export function* bookingWatcherSaga(): SagaIterator {
  yield takeEvery(bookingActions.getActivities.type, onGetActivities)
  yield takeEvery(bookingActions.getActivityImg.type, onGetActivityImg)
  yield takeEvery(bookingActions.getCityImg.type, onGetCityImg)
  yield takeEvery(bookingActions.getLocations.type, onGetLocations)
  yield takeEvery(bookingActions.getVendors.type, onGetVendors)
}

export default bookingWatcherSaga
