import React from 'react'

import DiscountBar from 'features/booking/DiscountBar'
import HeroActivity from 'features/booking/HeroActivity'
import Header from 'features/common/components/layouts/Header'
import getActivity from 'features/common/helpers/getActivity'
import useCity from 'features/common/helpers/useCity'

const BookingPage = () => {
  const activity = getActivity()
  const city = useCity()

  return (
    <div className="flex flex-col min-h-[calc(100vh-62px)]">
      <Header />
      <DiscountBar />
      <HeroActivity activity={activity} city={city} />
    </div>
  )
}

export default BookingPage
