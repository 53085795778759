import React, { useEffect, useMemo } from 'react'

import SearchSelect from 'features/common/components/SearchSelect'
import { useBookingService } from 'slices/booking/hooks'

export interface ActivitySelectProps {
  value: string
  onChange: (value: string) => void
}

const ActivitySelect = ({ value, onChange }: ActivitySelectProps) => {
  const {
    booking: { activities, loading },
    getActivities,
  } = useBookingService()

  const options = useMemo(() => {
    const filteredActivities = activities.filter(item => !!item.display_name)
    return filteredActivities.map(item => ({
      value: item.short_name.toLowerCase().split(' ').join(''),
      label: item.short_name,
    }))
  }, [activities])

  useEffect(() => {
    getActivities()
  }, [getActivities])

  return (
    <SearchSelect
      label="Activity"
      value={value}
      options={options}
      onChange={onChange}
      loading={loading}
    />
  )
}

export default ActivitySelect
