import createSagaMiddleware from '@redux-saga/core'
import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import logger from 'redux-logger'

import booking from 'slices/booking/store/booking.slice'
import bookSummary from 'slices/bookSummary/store/bookSummary.slice'
import currency from 'slices/currency/store/currency.slice'
import discountBar from 'slices/discountBar/store/discountBar.slice'
import { rootSaga } from 'store/rootSaga'

import CONFIG from '../config'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: process.env.REACT_APP_ENV === 'development',
  savePreviousLocations: 1,
})

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: combineReducers({
      booking,
      bookSummary,
      discountBar,
      currency,
      router: routerReducer,
    }),
    devTools: CONFIG.debug,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: false })
        .concat(sagaMiddleware)
        .concat(routerMiddleware)
        .concat(logger),
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export const store = makeStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const history = createReduxHistory(store)
