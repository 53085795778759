import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { Currency } from 'features/common/types'
import { getCurrencies, getDefaultCurrency } from 'slices/currency/api'
import { currencyActions } from 'slices/currency/store/currency.slice'

function* onGetCurrencies({
  payload,
}: {
  type: typeof currencyActions.getCurrencies
  payload: string | undefined
}): SagaIterator {
  yield put(currencyActions.getCurrenciesStart())
  try {
    const res: { currencies: Currency[] } = yield call(getCurrencies, payload)
    yield put(currencyActions.getCurrenciesSuccess(res.currencies))
  } catch (e) {
    yield put(currencyActions.getCurrenciesFailure(e))
  }
}

function* onGetDefaultCurrency({
  payload,
}: {
  type: typeof currencyActions.getDefaultCurrency
  payload: string
}): SagaIterator {
  yield put(currencyActions.getDefaultCurrencyStart())
  try {
    const res: { currencies: string[] } = yield call(getDefaultCurrency, payload)
    if (res.currencies.length > 0) {
      yield put(currencyActions.getDefaultCurrencySuccess(res.currencies[0]))
    } else {
      yield put(currencyActions.getDefaultCurrencySuccess(undefined))
    }
  } catch (e) {
    yield put(currencyActions.getCurrenciesFailure(e))
  }
}

// Watcher Saga
export function* currencyWatcherSaga(): SagaIterator {
  yield takeEvery(currencyActions.getCurrencies.type, onGetCurrencies)
  yield takeEvery(currencyActions.getDefaultCurrency.type, onGetDefaultCurrency)
}

export default currencyWatcherSaga
