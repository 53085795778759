import React from 'react'

const NotFound = () => (
  <div className="w-screen max-w-full h-screen max-h-full bg-gray-200 flex items-center justify-center">
    <section className="h-auto bg-[url('assets/images/section-b-hero-bg.jpg')] bg-cover mb-5 overflow-hidden text-white italic flex">
      <div className="max-w-screen-xl h-screen mx-auto pt-[30vh] px-4 block">
        <div className="grid auto-cols-fr gap-x-14 gap-y-8 grid-cols-2 lg:gap-x-4 md:text-center md:flex-col-reverse md:items-center md:grid-cols-1">
          <div className="mt-8 text-center flex flex-col items-center">
            <h1 className="mb-4 text-6xl font-bold italic md:text-4xl">
              The page you are looking for doesn&apos;t exist or has been moved.
            </h1>
            <div className="mt-5 text-3xl italic md:mt-2.5 md:text-2xl">
              404...
              <br />
              Page Not Found
            </div>
            <div className="w-full mt-16 flex flex-wrap lg:mt-8 md:mt-4 md:justify-center">
              <a
                href="/"
                className="bg-blue-500 rounded-none border-0 py-2.5 px-4 text-white no-underline cursor-pointer inline-block"
              >
                Back to home page
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default NotFound
