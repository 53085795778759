import React, { useMemo } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import AdaptiveImage from 'features/common/components/AdaptiveImage'
import { File } from 'features/common/types'

export interface ImageSliderProps {
  files: File[]
}

const ImageSlider = ({ files }: ImageSliderProps) => {
  const renderFiles = useMemo(() => {
    if (files.length <= 3) {
      return files
    }
    return files.slice(0, 3)
  }, [files])

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showIndicators={true}
      infiniteLoop={true}
      autoPlay={true}
      stopOnHover={true}
      swipeable={true}
      emulateTouch={true}
      showArrows={false}
    >
      {renderFiles.map((item, index) => (
        <div className="flex justify-center items-center h-full" key={index}>
          <AdaptiveImage file={item} alt="" />
        </div>
      ))}
    </Carousel>
  )
}

export default ImageSlider
