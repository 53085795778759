import { useCallback } from 'react'

import { Location, Vendor } from 'features/common/types'
import { bookingActions, BookingState, selectBooking } from 'slices/booking/store'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type BookingOperators = {
  booking: BookingState
  getActivities: (search?: string) => void
  getActivityImg: (shortName: string) => void
  getCityImg: (shortName: string) => void
  getLocations: (locationName: string, activityName: string) => void
  setLocations: (locations: Location[]) => void
  setSelectedLocations: (locations: Location[]) => void
  setLocationName: (name: string) => void
  getVendors: (data: {
    city: string
    shortName: string
    selectedLocations: Location[]
    areas: Location[]
    selectedCurrencyCode: string | undefined
  }) => void
  setVendorsLimit: (limit: number) => void
  setVendors: (vendors: Vendor[]) => void
}

export const useBookingService = (): Readonly<BookingOperators> => {
  const dispatch = useAppDispatch()

  return {
    booking: useAppSelector(selectBooking),
    getActivities: useCallback(
      (search?: string) => dispatch(bookingActions.getActivities(search)),
      [dispatch],
    ),
    getActivityImg: useCallback(
      (shortName: string) => dispatch(bookingActions.getActivityImg(shortName)),
      [dispatch],
    ),
    getCityImg: useCallback(
      (city: string) => dispatch(bookingActions.getCityImg(city)),
      [dispatch],
    ),
    getLocations: useCallback(
      (locationName: string, activityName: string) =>
        dispatch(bookingActions.getLocations(locationName, activityName)),
      [dispatch],
    ),
    setLocations: useCallback(
      (locations: Location[]) => dispatch(bookingActions.setLocations(locations)),
      [dispatch],
    ),
    setSelectedLocations: useCallback(
      (locations: Location[]) => dispatch(bookingActions.setSelectedLocations(locations)),
      [dispatch],
    ),
    setLocationName: useCallback(
      (name: string) => dispatch(bookingActions.setLocationName(name)),
      [dispatch],
    ),
    getVendors: useCallback(data => dispatch(bookingActions.getVendors(data)), [dispatch]),
    setVendorsLimit: useCallback(
      (limit: number) => dispatch(bookingActions.setVendorsLimit(limit)),
      [dispatch],
    ),
    setVendors: useCallback(
      (vendors: Vendor[]) => dispatch(bookingActions.setVendors(vendors)),
      [dispatch],
    ),
  }
}

export default useBookingService
