import moment from 'moment'

export function makeWhatsappURL(
  activity: string | null,
  city: string,
  vendor: { price?: number[]; currency?: { code: string }[]; vendor_name?: string } | null = null,
  noVendors = false,
  date = null,
  time = null,
) {
  const baseUrl = 'https://wa.me/442045388510'
  let message = 'Hello! I would like book a watersport activity'

  if (activity !== null) {
    message = `Hello! I would like to book a ${activity}`
  }

  if (city !== 'your location') {
    message += ` in ${city}`
  }

  if (vendor !== null) {
    let formattedPrice = ''
    if (vendor.price && vendor.price[0] && vendor.currency) {
      const currency = vendor.currency[0]?.code
      formattedPrice = `${vendor.price[0].toFixed(2)} ${currency}`
    }
    message += ` from ${vendor.vendor_name?.replace('&', 'and')}`
    if (formattedPrice.length) message += ` ${formattedPrice}`
  }

  if (date !== null) {
    message += ` on date: ${date}`
  }

  if (time !== null) {
    message += ` at ${time}`
  }

  if (noVendors) {
    message += ' but there were 0 options found on your website'
  }

  return `${baseUrl}?text=${message}`
}

export function getDayOfWeek(obj: moment.Moment) {
  let day = ''
  const wd = obj.day()
  if (wd === 0) day = 'Sunday'
  else if (wd === 1) day = 'Monday'
  else if (wd === 2) day = 'Tuesday'
  else if (wd === 3) day = 'Wednesday'
  else if (wd === 4) day = 'Thursday'
  else if (wd === 5) day = 'Friday'
  else if (wd === 6) day = 'Saturday'

  if (moment().format('MM-DD-YYYY') === obj.format('MM-DD-YYYY')) day = 'Today'
  else if (moment().add(1, 'days').format('MM-DD-YYYY') === obj.format('MM-DD-YYYY'))
    day = 'Tomorrow'

  return day
}

export function formatTime(date: string | moment.Moment) {
  return moment(date).format('hh:mm')
}

export function getResponsiveImage(
  file: { formatted: { md: string; lg: string } },
  isMobile: boolean,
) {
  if (file?.formatted) return isMobile ? file.formatted.md : file.formatted.lg
  return null
}

export const validateEmail = (email: string): RegExpMatchArray | null =>
  email.match(
    /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*\.[a-z]{2,4}$/,
  )
