import React from 'react'

export interface VendorDetailItemProps {
  title: string
  children: React.ReactNode
}

const VendorDetailItem: React.FC<VendorDetailItemProps> = ({ title, children }) => (
  <div className="flex items-start p-0 border-zinc-300 border-b border-solid">
    <span className="w-24 mt-5 mx-1.5 mb-2.5 text-lg text-gray-900 italic text-left">{title}</span>
    <div className="flex w-full mt-5 mx-1.5 mb-2.5 text-lg font-medium text-gray-900 text-left whitespace-nowrap text-ellipsis overflow-hidden">
      {children}
    </div>
  </div>
)

export default VendorDetailItem
