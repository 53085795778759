import moment from 'moment'
import React from 'react'

const Footer = () => (
  <section className="items-start pt-0 pb-10 text-white bg-black md:mx-0 md:pt-10 md:px-5 md:pb-28 md:text-left">
    <div className="block max-w-6xl px-0 py-6 mx-auto md:w-full md:py-2">
      <div className="border-b border-solid border-gray-600 grid auto-cols-fr gap-x-4 gap-y-0 grid-cols-[2.5fr_1fr_1fr_1fr_0.5fr] grid-rows-[100px_1fr] md:grid-cols-3 sm:!grid-cols-2">
        <a
          href="https://w-sports.click/"
          id="w-node-_62d3d7ab-d3a7-94e8-18b9-cf7d7e7dc2ac-7e7dc2a9"
          className="flex flex-col items-start max-w-md"
          rel="noreferrer"
        >
          <img
            src={'/static/images/header-logo.svg'}
            loading="lazy"
            alt=""
            className="h-16 mb-6 w-80"
          />
        </a>
        <div
          id="w-node-_62d3d7ab-d3a7-94e8-18b9-cf7d7e7dc2ba-7e7dc2a9"
          className="flex flex-col items-start max-w-md"
        >
          <ul role="list" className="pl-0 list-none">
            <li className="mb-4 text-base">
              <h4 className="my-0 text-base font-bold">For Adventurers</h4>
            </li>
            <li className="mb-4 text-base">
              <div>Book an activity</div>
            </li>
            <li className="mb-4 text-base">
              <div>Rent equipment</div>
            </li>
            <li className="mb-4 text-base">
              <div>Buy a watertoy</div>
            </li>
            <li className="mb-4 text-base">
              <div>Download the app</div>
            </li>
          </ul>
        </div>
        <div
          id="w-node-_62d3d7ab-d3a7-94e8-18b9-cf7d7e7dc2cb-7e7dc2a9"
          className="flex flex-col items-start max-w-md"
        >
          <ul role="list" className="pl-0 list-none">
            <li className="mb-4 text-base">
              <h4 className="my-0 text-base font-bold">For Business</h4>
            </li>
            <li className="mb-4 text-base">
              <div>Booking Manager</div>
            </li>
            <li className="mb-4 text-base">
              <div>Reach more clients</div>
            </li>
            <li className="mb-4 text-base">
              <div>Booking Manager</div>
            </li>
            <li className="mb-4 text-base">
              <div>Manage inventory</div>
            </li>
            <li className="mb-4 text-base">
              <div>Buy watertoys</div>
            </li>
          </ul>
        </div>
        <div className="flex flex-col items-start max-w-md">
          <ul role="list" className="pl-0 list-none">
            <li className="mb-4 text-base">
              <h4 className="my-0 text-base font-bold">For Distributors</h4>
            </li>
            <li className="mb-4 text-base">
              <div>Become a partner</div>
            </li>
            <li className="mb-4 text-base">
              <div>Cross-sell watertoys</div>
            </li>
          </ul>
        </div>
        <div
          id="w-node-_62d3d7ab-d3a7-94e8-18b9-cf7d7e7dc2f1-7e7dc2a9"
          className="flex flex-col items-start max-w-md"
        >
          <ul role="list" className="pl-0 list-none">
            <li className="mb-4 text-base">
              <h4 className="my-0 text-base font-bold">About</h4>
            </li>
            <li className="mb-4 text-base">
              <div>Locations</div>
            </li>
            <li className="mb-4 text-base">
              <a href={'/faq'} className="text-white no-underline">
                F.A.Q.
              </a>
            </li>
          </ul>
        </div>
        <div
          id="w-node-_75403119-22d3-adc8-41c4-41171df0eec5-7e7dc2a9"
          className="flex flex-col items-start max-w-md md:flex-col-reverse"
        >
          <div>
            For more stories, product announcements, events, and walkthroughs, subscribe our
            newsletter:
          </div>
          <div className="flex-row w-full mx-0 mt-6 mb-4">
            <form
              id="wf-form-Sign-Up-Form"
              name="wf-form-Sign-Up-Form"
              data-name="Sign Up Form"
              action="https://watersports-everywhere.us20.list-manage.com/subscribe/post?u=1511997ccbb5ed3a30d89eb42&amp;amp;id=0b1e41ea3c"
              method="post"
              className="relative flex flex-row items-stretch w-full bg-white rounded-lg"
            >
              <img
                src={'/static/images/Envelope__icon.svg'}
                loading="lazy"
                alt=""
                className="h-full py-4 pl-6 absolute left-0 top-0 right-auto bottom-auto z-0 sm:pl-2.5"
              />
              <input
                type="email"
                className="pl-6 mb-0 text-lg text-center bg-transparent rounded-l-lg h-14 w-input"
                maxLength={256}
                name="EMAIL"
                data-name="EMAIL"
                placeholder="Your email address"
                id="EMAIL-2"
                required={true}
              />
              <input
                type="submit"
                id="btn-footer-subscribe"
                value="Subscribe"
                data-wait="Please wait..."
                className="inline-block px-4 py-2 text-white no-underline bg-teal-500 border-0 rounded-r-lg cursor-pointer"
              />
            </form>
            <div className="hidden p-5 text-center bg-gray-400">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="bg-pink-200 mt-2.5 p-2.5 hidden">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_839acfff-37d1-14c2-ee39-216d4ca099df-7e7dc2a9"
          className="flex flex-col items-start max-w-md"
        >
          <ul role="list" className="mt-6 mb-0 lg:mt-2.5 sm:mt-0 pl-0 list-none">
            <li className="mb-4 text-base">
              <h4 className="my-0 text-base font-bold">For Developers</h4>
            </li>
            <li className="mb-4 text-base">
              <div>Watertoy booking API</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex items-center justify-between md:flex-col md:items-start">
        <div className="mt-5">
          <div className="">
            ©{moment().year()} Advanced Boat Booking and more, LTD{' '}
            <span className="italic font-light text-white">All rights reserved. </span>
            <a href={'/privacy-policy'} className="text-white">
              <span className="italic font-light text-white">Privacy.</span>
            </a>
            &nbsp;
            <a href={'/terms-conditions'} className="text-white">
              <span className="italic font-light text-white">Terms.</span>
            </a>
          </div>
          <div className="mt-2">
            80 Cheapside, EC2V 6EE, London, England. Registered 12942371.
          </div>          
        </div>
        <img
          src={'/static/images/Language-selector-Frame.svg'}
          loading="lazy"
          alt=""
          className="w-28 h-6 flex justify-between md:mt-2.5 md:mx-auto sm:text-lg"
        />
      </div>
    </div>
  </section>
)

export default Footer
