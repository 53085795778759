import React from 'react'
import { isMobile } from 'react-device-detect'

import { useSearch } from 'contexts/searchContext'
import getActivity from 'features/common/helpers/getActivity'
import WhatsappURL from 'features/common/helpers/whatsappURL'

export interface NavProps {
  toggleNavOpen: () => void
}

const Nav = ({ toggleNavOpen }: NavProps) => {
  const activity = getActivity()
  const whatsappUrl = WhatsappURL()
  const { setShow } = useSearch()

  return (
    <nav
      role="navigation"
      className="ml-auto flex items-center !z-10 lg:bg-white lg:p-0 md:w-full md:h-screen md:py-2.5 md:px-0 w-nav-menu"
    >
      <div className="hidden mx-auto pt-0 px-0 pb-5 items-center lg:border-b lg:border-solid lg:border-gray-400 lg:flex-col lg:items-start md:flex">
        <a
          href="https://w-sports.click/"
          className="mx-auto py-1.5 px-5 text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top hover:text-white lg:w-full lg:mx-0 lg:pl-0 md:border-b md:border-solid md:border-gray-400 md:py-4 md:px-2.5 md:text-lg md:flex md:items-center"
          rel="noreferrer"
        >
          <img
            src={'/static/images/ic_home.svg'}
            loading="lazy"
            alt=""
            className="w-6 h-6 mr-2.5"
          />
          Homepage
        </a>
        <a
          href="#"
          className="mx-auto py-1.5 px-5 text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top hover:text-white lg:w-full lg:mx-0 lg:pl-0 md:py-4 md:px-2.5 md:text-lg md:block"
          onClick={() => toggleNavOpen()}
        >
          Jet Ski
        </a>
        <a
          href="#"
          className="mx-auto py-1.5 px-5 text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top hover:text-white lg:w-full lg:mx-0 lg:pl-0 md:py-4 md:px-2.5 md:text-lg md:block"
          onClick={() => toggleNavOpen()}
        >
          Wakeboard
        </a>
        <a
          href="#"
          className="mx-auto py-1.5 px-5 text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top hover:text-white lg:w-full lg:mx-0 lg:pl-0 md:py-4 md:px-2.5 md:text-lg md:block"
          onClick={() => toggleNavOpen()}
        >
          Banana
        </a>
        <a
          href="#"
          className="mx-auto py-1.5 px-5 text-base text-gray-700 text-left no-underline flex-row relative inline-block align-top hover:text-white lg:w-full lg:mx-0 lg:pl-0 md:py-4 md:px-2.5 md:text-lg md:block"
          onClick={() => toggleNavOpen()}
        >
          Paddle-Board
        </a>
        <a
          href={whatsappUrl}
          id="btn-header-search-now"
          target="_blank"
          className="h-12 bg-teal-500 rounded border-0 my-2.5 py-0 px-4 text-base font-medium text-white text-center no-underline cursor-pointer italic flex items-center justify-center hover:bg-cyan-600 md:mx-0 md:mb-0"
          rel="noreferrer"
        >
          Explore more
        </a>
      </div>
      <div className="mr-5 text-gray-700 flex md:my-0 md:mx-2.5 md:py-5 md:text-lg md:text-left md:hidden">
        {activity}
      </div>
      <div className="mr-5 text-gray-700 flex items-center md:my-0 md:mx-2.5 md:py-5 md:text-lg md:text-left">
        <img src={'/static/images/ic_phone.png'} loading="lazy" alt="" className="w-4 h-4 mr-2" />
        +44 20 4538 8510
      </div>
      <a
        href={whatsappUrl}
        id="btn-header-whatsapp-now"
        target="_blank"
        className="w-48 h-12 bg-green-500 rounded my-2.5 mr-5 py-0 px-4 text-base font-medium text-white text-center no-underline flex items-center justify-center hover:opacity-80 md:mr-0"
        rel="noreferrer"
      >
        <img
          src={'/static/images/Whatssap-icon.svg'}
          loading="lazy"
          alt=""
          className="w-8 h-8 mr-2"
        />
        <div>WhatsApp Now</div>
      </a>
      {!isMobile ? (
        <a
          href="#"
          id="btn-header-search-now"
          className="w-40 h-12 bg-gray-800 rounded border-0 my-2.5 py-0 px-4 text-base font-medium text-white text-center no-underline cursor-pointer flex items-center justify-center md:mx-0 md:mb-0"
          onClick={() => setShow(true)}
        >
          Search now
        </a>
      ) : (
        ''
      )}
    </nav>
  )
}

export default Nav
