import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import CONFIG from './config'
import { getGtmId } from './constant/gtag'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'

const tagManagerArgs = {
  gtmId: getGtmId(),
}

TagManager.initialize(tagManagerArgs)

if (!CONFIG.debug) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
    plugins: [new BugsnagPluginReact()],
    appVersion: '0.1.0',
    releaseStage: process.env.REACT_APP_ENV,
  })
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <App />
        <ToastContainer />
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
)

reportWebVitals()
