import React, { useEffect, useState } from 'react'

export interface CheckboxProps {
  value: string | boolean
  checked: boolean
  onChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string | boolean) => void
  gray?: boolean | undefined
  empty?: boolean | undefined
}

const Checkbox = ({ value, checked, onChange, gray, empty }: CheckboxProps) => {
  const [status, setStatus] = useState(checked)

  useEffect(() => {
    setStatus(checked)
  }, [checked])

  const updateStatus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setStatus(checked)
    onChange(e, value)
  }
  return (
    <div
      className={`${
        empty ? 'border border-stone-300' : 'border border-slate-500'
      } p-1 rounded-[1px] flex items-center justify-center`}
      onClick={updateStatus}
    >
      <div
        className={`w-2.5 h-2.5 rounded-sm ${
          status ? (gray ? 'bg-stone-300' : 'bg-cyan-600') : 'bg-white'
        }`}
      />
    </div>
  )
}

export default Checkbox
