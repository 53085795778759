import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Activity, File, Location, Vendor } from 'features/common/types'
import type { RootState } from 'store/store'

export interface BookingState {
  loading: boolean
  activityImg: File[]
  cityImg: File | undefined
  locationName: string
  locations: Location[]
  selectedLocations: Location[]
  activities: Activity[]
  vendorsLimit: number
  vendors: Vendor[]
  error: unknown | undefined
}

const initialState: BookingState = {
  loading: false,
  activityImg: [],
  cityImg: undefined,
  locationName: '',
  locations: [],
  selectedLocations: [],
  activities: [],
  vendorsLimit: 5,
  vendors: [],
  error: undefined,
}

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    getActivitiesStart(state) {
      state.loading = true
    },
    getActivitiesSuccess(state, action: PayloadAction<Activity[]>) {
      state.activities = action.payload
      state.loading = false
      state.error = undefined
    },
    getActivitiesFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.activities = []
    },

    getActivityImgStart(state) {
      state.loading = true
    },
    getActivityImgSuccess(state, action: PayloadAction<File[]>) {
      state.activityImg = action.payload
      state.loading = false
      state.error = undefined
    },
    getActivityImgFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.activityImg = []
    },

    getCityImgStart(state) {
      state.loading = true
    },
    getCityImgSuccess(state, action: PayloadAction<File>) {
      state.cityImg = action.payload
      state.loading = false
      state.error = undefined
    },
    getCityImgFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.cityImg = undefined
    },

    getLocationsStart(state) {
      state.loading = true
    },
    getLocationsSuccess(state, action: PayloadAction<Location[]>) {
      state.locations = action.payload
      state.selectedLocations = action.payload
      state.loading = false
      state.error = undefined
    },
    getLocationsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.locations = []
      state.selectedLocations = []
    },

    getVendorsStart(state) {
      state.loading = true
    },
    getVendorsSuccess(state, action: PayloadAction<Vendor[]>) {
      state.vendors = action.payload
      state.loading = false
      state.error = undefined
    },
    getVendorsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.vendors = []
    },

    setVendorsLimit(state, action) {
      state.vendorsLimit = action.payload
    },
    setVendors(state, action) {
      state.vendors = action.payload
    },

    setLocations(state, action: PayloadAction<Location[]>) {
      state.locations = action.payload
    },
    setSelectedLocations(state, action: PayloadAction<Location[]>) {
      state.selectedLocations = action.payload
    },
    setLocationName(state, action: PayloadAction<string>) {
      state.locationName = action.payload
    },
  },
})

export const bookingActions = {
  getActivities: createAction(`${slice.name}/getActivities`, (search?: string) => ({
    payload: search,
  })),
  getActivitiesStart: slice.actions.getActivitiesStart,
  getActivitiesSuccess: slice.actions.getActivitiesSuccess,
  getActivitiesFailure: slice.actions.getActivitiesFailure,

  getActivityImg: createAction(`${slice.name}/getActivityImg`, (shortName: string) => ({
    payload: shortName,
  })),
  getActivityImgStart: slice.actions.getActivityImgStart,
  getActivityImgSuccess: slice.actions.getActivityImgSuccess,
  getActivityImgFailure: slice.actions.getActivityImgFailure,

  getCityImg: createAction(`${slice.name}/getCityImg`, (city: string) => ({ payload: city })),
  getCityImgStart: slice.actions.getCityImgStart,
  getCityImgSuccess: slice.actions.getCityImgSuccess,
  getCityImgFailure: slice.actions.getCityImgFailure,

  getLocations: createAction(
    `${slice.name}/getLocations`,
    (locationName: string, activityName: string) => ({ payload: { locationName, activityName } }),
  ),
  getLocationsStart: slice.actions.getLocationsStart,
  getLocationsSuccess: slice.actions.getLocationsSuccess,
  getLocationsFailure: slice.actions.getLocationsFailure,

  getVendors: createAction(
    `${slice.name}/getVendors`,
    (data: {
      city: string
      shortName: string
      selectedLocations: Location[]
      areas: Location[]
      selectedCurrencyCode: string | undefined
    }) => ({
      payload: data,
    }),
  ),
  getVendorsStart: slice.actions.getVendorsStart,
  getVendorsSuccess: slice.actions.getVendorsSuccess,
  getVendorsFailure: slice.actions.getVendorsFailure,

  setVendorsLimit: slice.actions.setVendorsLimit,
  setVendors: slice.actions.setVendors,
  setLocations: slice.actions.setLocations,
  setSelectedLocations: slice.actions.setSelectedLocations,
  setLocationName: slice.actions.setLocationName,
}

export const selectBooking = (state: RootState) => state.booking

export default slice.reducer
