import parse from 'html-react-parser'
import React from 'react'

import Footer from 'features/common/components/layouts/Footer'
import Header from 'features/common/components/layouts/Header'

import { TERMS_CONDITIONS_ITEMS } from '../constant'

const TermsConditionsPage = () => (
  <div className="flex flex-col">
    <Header />
    <div className="flex flex-col py-24 px-20 lg:py-10 lg:px-8">
      <span className="mb-28 text-7xl font-semibold text-teal-500 uppercase lg:mb-16 lg:text-3xl">
        Terms and Conditions
      </span>
      <span className="mb-20 text-lg font-medium text-black">
        Terms of Service Watersports Everywhere
        <br />
        V1.0 - 25th of April 2022
        <br />
        ADVANCED BOAT BOOKING AND MORE LTD
        <br />
        11 Staple Inn, London, United Kingdom, WC1V 7QH
        <br />
        terms@watersports-everywhere.com
      </span>
      <span className="text-lg text-black">
        These Terms of Use shall govern the Watersports Everywhere software application service (the
        “Service”), including, but not limited to, the customer water sports equipment rental as
        provided or made available by ADVANCED BOAT BOOKING AND MORE LTD (ABBM). Please read
        carefully the terms and conditions contained herein. The downloading of the Watersports
        Everywhere application, registration for, or use of the Service shall be deemed to be your
        agreement to abide by this Terms of Use including any materials available on the Watersports
        Everywhere software application site incorporated by reference herein (
        <a href={'www.watersports-everywhere.com'}>www.watersports-everywhere.com</a>
        ), including but not limited to Watersports Everywhere Privacy Policy and Watersports
        Everywhere End User License Agreement and will constitute your ongoing acceptance of the
        Watersports Everywhere Terms of Use.
        <br />
        <br />
        <b>Table of contents</b>
        <ol>
          <li>Introduction</li>
          <li>Watersports Everywhere Responsibilities</li>
          <li>User Responsibilities</li>
          <li>Conditions of Use of the Services and Content</li>
          <li>Fees and Payment for Purchased Services</li>
          <li>Cancellations &amp; Termination</li>
          <li>Confidentiality and Privacy</li>
          <li>Legal Information</li>
          <li>Refund</li>
        </ol>
      </span>
      <div className="flex flex-col mt-5">
        {TERMS_CONDITIONS_ITEMS.map((termsItem, index) => (
          <div className="mb-4 text-lg text-black flex flex-col" key={index}>
            <b>
              {index + 1}. {termsItem.title}
            </b>
            <span>{parse(termsItem.content)}</span>
          </div>
        ))}
      </div>
    </div>
    <Footer />
  </div>
)

export default TermsConditionsPage
