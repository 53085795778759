import React, { ChangeEvent, useMemo, useRef, useState } from 'react'

import { usePopup } from 'libs/hooks'

import SvgIcon from '../SvgIcon'

export interface IOption {
  label: string
  value: string
  group?: string
  options?: {
    label: string
    value: string
  }[]
}

export interface SearchSelectProps {
  value: string
  onChange: (value: string) => void
  options: IOption[]
  group?: boolean
  loading: boolean
  label: string
  onSearchChange?: (value: string) => void
}

const SearchSelect = ({
  label,
  value,
  onChange = () => {},
  options,
  group = false,
  loading = false,
  onSearchChange,
}: SearchSelectProps) => {
  const ref = useRef(null)
  const { isOpen, openMenu, closeMenu } = usePopup(ref)
  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target?.value || '')
    if (onSearchChange) onSearchChange(e.target?.value)

    if (!isOpen) {
      openMenu()
    }
  }

  const onSelectOption = (option: IOption) => {
    setSearchValue(option.label)
    onChange(option.value)
    closeMenu()
  }

  const filterOptions = useMemo(() => {
    if (group) {
      return options
        .map(item => ({
          label: item.label,
          value: item.value,
          group: item?.group,
          options: item?.options,
        }))
        .filter(item => !!item?.options?.length)
    }
    return options.filter(item => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
  }, [searchValue, group, options])

  const renderGroupField = (option: IOption) => (
    <>
      <div className="py-1.5 px-2.5 text-black text-left not-italic">{option.group}</div>
      <div className="ml-4">
        {option.options?.map((item, jdx) => (
          <div
            className="py-1.5 px-2.5 text-gray-600 text-left not-italic cursor-pointer hover:bg-gray-300"
            key={jdx}
            onClick={() => onSelectOption(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </>
  )

  const renderOptions = () => (
    <>
      {filterOptions.map((option, index) => (
        <>
          {group ? (
            <div key={index}>{renderGroupField(option)}</div>
          ) : (
            <div
              className="py-1.5 px-2.5 text-gray-600 text-left not-italic cursor-pointer hover:bg-gray-300"
              key={index}
              onClick={() => onSelectOption(option)}
            >
              {option.label}
            </div>
          )}
        </>
      ))}
    </>
  )

  return (
    <div className="w-80 text-lg relative">
      <div
        className={`h-11 bg-white py-2 px-3.5 flex items-center ${
          isOpen ? 'rounded-t' : 'rounded'
        }`}
        ref={ref}
        onClick={openMenu}
      >
        <SvgIcon className="min-w-[18px]" name="search" width={18} />
        <div className="my-0 mr-2.5 ml-3.5 font-semibold text-teal-500 italic">{`${label}${
          searchValue ? ':' : ''
        }`}</div>
        <input
          className="w-48 border-0 pr-5 text-gray-600 not-italic focus:outline-none"
          value={searchValue}
          onChange={handleSearchChange}
        />
        {value && <SvgIcon className="min-w-[18px] absolute right-3.5" name="chevron-down" />}
      </div>
      {isOpen && (
        <div className="w-full max-h-52 bg-white rounded-b px-1.5 px-0 absolute top-full z-10 overflow-auto">
          {loading ? (
            <div className="p-2.5 text-gray-600 not-italic">Loading...</div>
          ) : (
            <>
              {filterOptions.length ? (
                renderOptions()
              ) : (
                <div className="p-2.5 text-gray-600 not-italic">No Options</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchSelect
