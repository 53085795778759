import React, { createContext, ReactNode, useState } from 'react'

const SearchContext = createContext({} as IContextProps)

interface IContextProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState(false)

  return (
    <SearchContext.Provider
      value={{
        show,
        setShow,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

function useSearch(): IContextProps {
  const context = React.useContext(SearchContext)

  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}

export { SearchProvider, useSearch }
