import moment from 'moment'
import React, { useCallback, useMemo } from 'react'

import { useBookSummaryService } from 'slices/bookSummary/hooks'

import Date from './Date'
import Time from './Time'

const Calendar = () => {
  const {
    bookSummary: { step, date, time, bookVendor },
    setShowCalendar,
    setShowBookNow,
    setDate,
    setTime,
    setStep,
  } = useBookSummaryService()

  const city = useMemo(() => {
    const vendorLocations = bookVendor?.vendor_location || []
    if (vendorLocations.length > 0) {
      return vendorLocations[0].city
    }
    return undefined
  }, [bookVendor?.vendor_location])

  const handleClickOutside = useCallback(() => {
    setShowCalendar(false)
    if (time && date) {
      setShowBookNow(true)
    } else {
      setDate(undefined)
      setTime(undefined)
    }
  }, [date, setDate, setShowBookNow, setShowCalendar, setTime, time])

  const onDateNext = (d: moment.Moment) => {
    setDate(d.format('YYYY-MM-DD'))
    setStep(2)
  }

  const onTimePrev = () => {
    setStep(1)
  }

  const onTimeNext = (t: string) => {
    setTime(t)
    setShowCalendar(false)
    setShowBookNow(true)
  }

  return (
    <div className="w-full h-screen fixed inset-0 z-[990] bg-[#00000099]">
      {step === 1 && <Date onNext={onDateNext} outsideClick={handleClickOutside} />}
      {step === 2 && (
        <Time
          date={date}
          time={time}
          city={city}
          onPrev={onTimePrev}
          onNext={onTimeNext}
          outsideClick={handleClickOutside}
        />
      )}
    </div>
  )
}

export default Calendar
