import React from 'react'

const ComingSoon = () => (
  <section className="bg-gray-100 py-10 not-italic relative md:py-4">
    <div className="max-w-6xl mx-auto py-6 px-0 block md:w-full md:py-2">
      <div className="mb-8 grid auto-cols-fr gap-x-14 gap-y-4 grid-cols-[1fr_minmax(50%,1fr)] lg:gap-x-4 md:text-center md:grid-cols-1 md:flex-col-reverse md:items-center">
        <div className="text-right flex flex-col items-end justify-center md:items-center md:justify-center">
          <h2 className="mt-5 text-3xl italic md:mt-2.5 md:text-lg md:font-medium md:text-gray-900 md:text-center">
            {'Coming soon to the AppStores'}
          </h2>
        </div>
        <div className="flex justify-start items-center md:justify-center">
          <img src={'/static/images/appstore.svg'} loading="lazy" alt="" className="w-44 h-14" />
          <img src={'/static/images/googleplay.svg'} loading="lazy" alt="" className="w-44 h-14" />
        </div>
      </div>
      <div className="text-center flex flex-col items-center justify-center">
        <div className="max-w-screen-md px-4">
          <p className="text-base text-black text-center italic md:not-italic">
            {'Get notified when the mobile app is available for download'}
          </p>
          <div className="w-full mt-6 mx-0 mb-4 flex-row">
            <form
              id="wf-form-Sign-Up-Form"
              name="wf-form-Sign-Up-Form"
              data-name="Sign Up Form"
              action="https://watersports-everywhere.us20.list-manage.com/subscribe/post?u=1511997ccbb5ed3a30d89eb42&amp;amp;id=0b1e41ea3c"
              method="post"
              className="w-full bg-white rounded-lg flex flex-row items-stretch relative"
            >
              <img
                src={'/static/images/Envelope__icon.svg'}
                loading="lazy"
                alt=""
                className="h-full py-4 pl-6 absolute left-0 top-0 right-auto bottom-auto z-0 sm:pl-2.5"
              />
              <input
                type="email"
                className="h-14 bg-transparent rounded-l-lg mb-0 pl-6 text-lg text-center italic md:text-base md:not-italic w-input"
                maxLength={256}
                name="EMAIL"
                data-name="EMAIL"
                placeholder="Your email address"
                id="EMAIL-2"
                required
              />
              <input
                type="submit"
                id="btn-coming-soon-get-notified"
                value="Get notified"
                data-wait="Please wait..."
                className="bg-teal-500 rounded-r-lg border-0 py-2 px-4 text-white no-underline cursor-pointer inline-block"
              />
            </form>
            <div className="hidden bg-gray-400 p-5 text-center">
              <div>{'Thank you! Your submission has been received!'}</div>
            </div>
            <div className="hidden bg-gray-400 p-5 text-center">
              <div>{'Oops! Something went wrong while submitting the form.'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ComingSoon
