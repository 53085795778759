import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import React, { useMemo, useCallback, useState } from 'react'

import Loading from 'features/common/components/Loading'
import { Currency as CurrencyType } from 'features/common/types'
import { currencyConversion } from 'slices/booking/api'
import { useBookingService } from 'slices/booking/hooks'
import { useBookSummaryService } from 'slices/bookSummary/hooks'
import { useCurrencyService } from 'slices/currency/hooks'

export interface CurrencyProps {
  setShowCurrencyNav: React.Dispatch<React.SetStateAction<boolean>>
}

const FIXED_CURRENCY_CODES = ['EUR', 'USD', 'GBP']

const Currency = ({ setShowCurrencyNav }: CurrencyProps) => {
  const {
    currency: { currencies, selectedCurrency },
    setSelectedCurrency,
  } = useCurrencyService()
  const {
    bookSummary: { bookVendor },
    setBookVendor,
  } = useBookSummaryService()
  const {
    booking: { locations, vendors },
    setLocations,
    setVendors,
  } = useBookingService()
  const [loadingCurrency, setLoadingCurrency] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [checkedCurrency, setCheckedCurrency] = useState(selectedCurrency)

  const sortedCurrencies = useMemo(() => {
    const fixed: CurrencyType[] = []

    FIXED_CURRENCY_CODES.forEach(code => {
      const matched = currencies.find(item => item.code === code)
      if (matched) {
        fixed.push(matched)
      }
    })

    const others = currencies.filter(item => !FIXED_CURRENCY_CODES.includes(item.code))
    return [...fixed, ...others]
  }, [currencies])

  const filtered = useMemo(
    () =>
      sortedCurrencies.filter(item => item.code.toLowerCase().includes(searchKey.toLowerCase())),
    [searchKey, sortedCurrencies],
  )

  const selectCurrency = useCallback((currency: CurrencyType) => {
    setCheckedCurrency(currency)
  }, [])

  const chooseCurrency = useCallback(
    async (currency: CurrencyType) => {
      try {
        let rate = 1
        if (currency?.code && currency?.code !== selectedCurrency?.code) {
          const data = {
            base_currency_code: selectedCurrency?.code || '',
            desired_currency_code: currency?.code || '',
          }
          setLoadingCurrency(true)
          const { conversion_rate: conversionRate } = await currencyConversion(data)
          rate = conversionRate
        }
        setSelectedCurrency(currency)

        if (vendors.length) {
          const reVendors = vendors.map(vendor => {
            const price = vendor?.price && vendor.price.length > 0 ? vendor.price[0] : 0
            return {
              ...vendor,
              price: [parseFloat((price * rate).toFixed(2))],
              formattedPrice: `${(price * rate).toFixed(2)} ${currency?.code || ''}`,
              discountedPrice: `${(price * rate * 0.8).toFixed(2)} ${currency?.code || ''}`,
            }
          })
          setVendors(reVendors)
        }
        if (locations.length) {
          const reLocations = locations.map(location => {
            const price = location.min_price ? location.min_price : 0
            return {
              ...location,
              min_price: parseFloat((price * rate).toFixed(2)),
              currency_code: currency?.code,
            }
          })
          setLocations(reLocations)
        }
        if (bookVendor) {
          const price = bookVendor?.price && bookVendor.price.length > 0 ? bookVendor.price[0] : 0
          setBookVendor({
            ...bookVendor,
            formattedPrice: `${(price * rate).toFixed(2)} ${currency?.code || ''}`,
            discountedPrice: `${(price * rate * 0.8).toFixed(2)} ${currency?.code || ''}`,
          })
        }
        setLoadingCurrency(false)
        setShowCurrencyNav(false)
      } catch (e) {
        setLoadingCurrency(false)
      }
    },
    [
      bookVendor,
      locations,
      selectedCurrency?.code,
      setBookVendor,
      setLocations,
      setSelectedCurrency,
      setShowCurrencyNav,
      setVendors,
      vendors,
    ],
  )

  const goBack = () => {
    setShowCurrencyNav(false)
  }

  return (
    <div className="w-full h-screen bg-white absolute top-0 z-50 overflow-hidden">
      {loadingCurrency && <Loading>{'Loading...'}</Loading>}
      <div className="w-full h-14 p-2.5 flex items-center justify-between">
        <div className="flex flex-row items-center">
          <div onClick={goBack}>
            <img src={'/static/images/arrow_back_ios.svg'} loading="lazy" alt="" />
          </div>
          <div className="ml-2.5 text-2xl text-black text-center">Choose currency</div>
        </div>
        {checkedCurrency && checkedCurrency !== selectedCurrency && (
          <CheckIcon
            className="h-8 w-8 mr-2 text-gray-700"
            onClick={() => chooseCurrency(checkedCurrency)}
          />
        )}
      </div>
      <div className="h-10 bg-white rounded-md border border-solid border-neutral-300 m-2.5 p-2.5 flex items-center">
        <MagnifyingGlassIcon className="h-6 w-6 text-gray-700" />
        <input
          className="w-full h-full outline-none ml-2 text-lg font-semibold text-neutral-900"
          onChange={e => setSearchKey(e.target.value)}
        />
      </div>
      <div className="w-full h-full overflow-y-auto px-2.5">
        {filtered.map((cur: CurrencyType, i: number) => (
          <div
            className={`w-full h-10 rounded-md border border-solid my-2.5 p-2.5 text-lg font-semibold flex items-center ${
              checkedCurrency?.code === cur.code
                ? 'bg-cyan-300 border-cyan-300 text-white'
                : 'bg-white border-neutral-300 text-neutral-900'
            }`}
            key={i}
            onClick={() => selectCurrency(cur)}
          >
            <div className="w-24">{cur.code}</div>
            <div className="font-normal text-gray-500 text-left text-ellipsis whitespace-nowrap overflow-hidden">
              {cur.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Currency
