import { all, fork } from 'redux-saga/effects'

import { bookingWatcherSaga } from 'slices/booking/store'
import { currencyWatcherSaga } from 'slices/currency/store'

export function* rootSaga() {
  yield all([fork(bookingWatcherSaga), fork(currencyWatcherSaga)])
}

export default rootSaga
