import moment from 'moment'
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'

import { TIME_VALUES } from 'constant'
import Button from 'features/common/components/Button'
import { useOutsideClick } from 'libs/hooks'

export interface TimeProps {
  date: string | undefined
  time: string | undefined
  city: string | undefined
  onPrev: () => void
  onNext: (value: string) => void
  outsideClick: () => void
}

const Time = ({ date, time, city, onNext, onPrev, outsideClick }: TimeProps) => {
  const wrapperRef = useRef(null)
  const [timeSelected, setTimeSelected] = useState(time)

  const timeList = useMemo(() => {
    const now = moment()
    return now.format('YYYY-MM-DD') === date
      ? TIME_VALUES.filter(item => moment(item, 'h:mm').isAfter(now))
      : TIME_VALUES
  }, [date])

  useEffect(() => {
    if (!timeSelected) {
      setTimeSelected(timeList[0])
    }
  }, [timeList, timeSelected])

  const handleClickOutside = useCallback(() => {
    outsideClick()
    onPrev()
  }, [onPrev, outsideClick])

  useOutsideClick(wrapperRef, handleClickOutside)

  return (
    <div
      className="w-full max-w-xl fixed z-[990] bg-white p-4 bottom-0 not-italic"
      ref={wrapperRef}
    >
      <span className="text-2xl">{'Select date and time'}</span>
      <div className="max-h-[250px] mt-[20px] mr-0 mb-2.5 overflow-y-auto">
        {timeList.map((t, i) => (
          <Button
            key={i}
            disabled={false}
            size="large"
            type={t === timeSelected ? 'normal' : 'light'}
            custom={
              t === timeSelected
                ? 'w-11/12 mt-1.5 mx-auto mb-2.5 !text-lg'
                : 'w-11/12 border border-solid border-neutral-800 mt-1.5 mx-auto mb-2.5 !text-lg !font-normal !text-neutral-800'
            }
            onClick={() => setTimeSelected(t)}
          >
            {t}
          </Button>
        ))}
      </div>
      <p className="text-2xl leading-6">
        {'Timezone: '}
        <b>{city}</b>
        {' local time'}
      </p>
      <div className="flex mt-8 mr-0 mb-2.5">
        <Button
          size="large"
          type="light"
          custom="border-2 border-solid border-slate-800 mr-2"
          disabled={false}
          onClick={() => onPrev()}
        >
          <i className="text-black">Back</i>
        </Button>
        <Button
          size="large"
          type="dark"
          disabled={false}
          onClick={() => onNext(timeSelected || timeList[0])}
        >
          <i>Next</i>
        </Button>
      </div>
    </div>
  )
}

export default Time
