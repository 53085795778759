import moment from 'moment'
import React, { useMemo } from 'react'

import getActivity from 'features/common/helpers/getActivity'
import useCity from 'features/common/helpers/useCity'
import { getDayOfWeek } from 'features/common/helpers/utils'
import { Vendor, Location } from 'features/common/types'
import { useBookSummaryService } from 'slices/bookSummary/hooks'
import { useDiscountBarService } from 'slices/discountBar/hooks'

import VendorDetailItem from './VendorDetailItem'

export interface VendorDetailsProps {
  fVendor: Vendor | undefined
  selectedLocations?: Location[]
}

const VendorDetails = ({ fVendor }: VendorDetailsProps) => {
  const activity = getActivity()
  const city = useCity()

  const {
    bookSummary: { date: propDate, time },
    setStep,
    setShowCalendar,
    setShowBookNow,
  } = useBookSummaryService()
  const {
    discountBar: { discountPrice, discounted },
    setDiscounted,
  } = useDiscountBarService()

  const date = useMemo(() => (propDate ? moment(propDate, 'YYYY-MM-DD') : undefined), [propDate])

  const vendorLocation = useMemo(() => {
    if (fVendor && fVendor.vendor_location && (fVendor.vendor_location?.length || 0) > 0) {
      return fVendor.vendor_location[0]
    }
    return undefined
  }, [fVendor])

  const editDateTime = (step: number) => {
    setShowCalendar(true)
    setShowBookNow(false)
    setStep(step)
  }

  return (
    <div className="p-5">
      {discounted && (
        <div className="flex items-center justify-between m-0 px-2.5 py-2 bg-black text-white">
          <div className="non-italic font-normal text-sm">
            20% discount code &ldquo;WELCOME20&ldquo; applied
          </div>
          <div className="non-italic font-normal text-sm" onClick={() => setDiscounted(false)}>
            <img src={'/static/images/header__close-white.svg'} loading="lazy" alt="" />
          </div>
        </div>
      )}
      <VendorDetailItem title={'Location:'}>{city}</VendorDetailItem>
      <VendorDetailItem title={'Area:'}>
        {(fVendor?.vendor_location?.length || 0) > 0 && city === vendorLocation?.city
          ? vendorLocation?.name
          : vendorLocation?.city}
        <br />
        {city === vendorLocation?.city ? '' : `at ${vendorLocation?.name}`}
      </VendorDetailItem>
      <VendorDetailItem title={'Activity:'}>{activity}</VendorDetailItem>
      <VendorDetailItem title={'Provider:'}>{fVendor?.vendor_name}</VendorDetailItem>
      <VendorDetailItem title={'Price:'}>
        <div className="text-green-600">
          {discountPrice ? (
            <>
              <span className="text-rose-500 line-through">{fVendor?.formattedPrice}</span>
              &nbsp;
              {fVendor?.discountedPrice}
            </>
          ) : (
            fVendor?.formattedPrice
          )}
        </div>
      </VendorDetailItem>
      {date && (
        <VendorDetailItem title={'Date:'}>
          <div className="grow">{`${getDayOfWeek(date)}, ${date.format('D MMMM')}`}</div>
          <span className="float-right pr-1" onClick={() => editDateTime(1)}>
            <img src={'/static/images/edit.svg'} loading="lazy" alt="" />
          </span>
        </VendorDetailItem>
      )}
      {time && (
        <VendorDetailItem title={'Time:'}>
          <div className="grow">
            {time} <span className="text-zinc-500 text-4 font-normal">(local time)</span>
          </div>
          <span className="float-right pr-1" onClick={() => editDateTime(2)}>
            <img src={'/static/images/edit.svg'} loading="lazy" alt="" />
          </span>
        </VendorDetailItem>
      )}
    </div>
  )
}

export default VendorDetails
