import { useState, useEffect, RefObject } from 'react'

type Event = MouseEvent | TouchEvent

export const usePopup = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current
      if (!el || el.contains((event?.target as Node) || null)) {
        return
      }
      setIsOpen(false)
    }

    if (isOpen) {
      window.addEventListener('click', listener)
    } else {
      window.removeEventListener('click', listener)
    }
  }, [ref, isOpen])

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)

  const toggleMenu = () => setIsOpen(!isOpen)

  return {
    isOpen,
    closeMenu,
    openMenu,
    toggleMenu,
  }
}
