import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { VERIFY_URL } from 'constant'
import AreaDropdown from 'features/booking/AreaDropdown'
import BookSummary from 'features/booking/BookSummary'
import Calendar from 'features/booking/Calendar'
import AdaptiveImage from 'features/common/components/AdaptiveImage'
import Button from 'features/common/components/Button'
import Loading from 'features/common/components/Loading'
import { makeWhatsappURL } from 'features/common/helpers/utils'
import { Vendor } from 'features/common/types'
import { requestBooking } from 'slices/booking/api'
import { useBookingService } from 'slices/booking/hooks'
import { useBookSummaryService } from 'slices/bookSummary/hooks'
import { useCurrencyService } from 'slices/currency/hooks'
import { useDiscountBarService } from 'slices/discountBar/hooks'

export enum BookingStep {
  ContactUs = 'ContactUs',
  SelectDateTime = 'SelectDateTime',
  BookNow = 'BookNow',
  Continue = 'Continue',
}

export interface HeroActivityProps {
  activity: string | null
  city: string
}

const HeroActivity: React.FC<HeroActivityProps> = ({ activity, city }) => {
  const shortName = window.location.hostname.split('.')[0] || ''

  const {
    discountBar: { discountPrice },
  } = useDiscountBarService()
  const {
    currency: { selectedCurrency },
  } = useCurrencyService()
  const {
    booking: { loading, cityImg, locations, selectedLocations, vendors },
    getActivityImg,
    getCityImg,
    getLocations,
    getVendors,
    setLocationName,
  } = useBookingService()
  const {
    bookSummary: { bookVendor, date, time, selectDateTime, showBookNow, showCalendar },
    setShowCalendar,
    setBookVendor,
    setSelectDateTime,
  } = useBookSummaryService()

  const [requesting, setRequesting] = useState(false)
  const [loadingCurrency, setLoadingCurrency] = useState(false)
  const [selectedVendor, selectVendor] = useState<Vendor | undefined>(undefined)

  const [areaDropdownVisible, setAreaDropdownVisible] = useState(false)
  const [cnScroll, setCnScroll] = useState('')

  useEffect(() => {
    getActivityImg(shortName)
  }, [getActivityImg, shortName])

  useEffect(() => {
    getCityImg(city)
  }, [city, getCityImg])

  useEffect(() => {
    setLocationName(city)
    getLocations(city, shortName)
  }, [city, getLocations, setLocationName, shortName])

  useEffect(() => {
    const screenHeight = window.innerHeight
    const element = document.getElementById('hero__section__main')
    const offsetHeight = element?.offsetHeight || 0
    if (screenHeight - 62 > offsetHeight) {
      setCnScroll('')
    } else {
      setCnScroll('scrollable')
    }
  }, [])

  useEffect(() => {
    if (vendors.length > 0) {
      selectVendor(vendors[0])
    }
  }, [vendors])

  useEffect(() => {
    if (selectedCurrency?.code) {
      getVendors({
        city,
        shortName,
        selectedLocations,
        areas: locations,
        selectedCurrencyCode: selectedCurrency?.code,
      })
    }
  }, [city, shortName, selectedLocations, locations, selectedCurrency?.code, getVendors])

  const continueNext = useCallback(() => {
    if (!selectedVendor) return
    setBookVendor(selectedVendor)
    setSelectDateTime(true)
    setLoadingCurrency(false)
  }, [selectedVendor, setBookVendor, setSelectDateTime])

  const requestBook = useCallback(async () => {
    try {
      setRequesting(true)

      const vendorLocations = bookVendor?.vendor_location || []
      const vendorLocation = vendorLocations.length > 0 ? vendorLocations[0] : undefined

      const currencies = bookVendor?.currency || []
      const currency = currencies.length > 0 ? currencies[0] : undefined

      const data = {
        discount_code: discountPrice ? 'WELCOME20' : '',
        location_id: vendorLocation?._id || '',
        inventory_id: bookVendor?.inventory_id || '',
        start_time: `${date}T${time}:00`,
        end_time: `${date}T${time}:00`,
      }
      const res = await requestBooking(
        data,
        'en',
        selectedCurrency?.code || currency?.code || 'usd',
      )
      setRequesting(false)
      window.location.href = `${VERIFY_URL}/${res.booking_id}/add-name`
    } catch (e: unknown) {
      setRequesting(false)
      const error = e as { data: { error: string } }
      if (error.data.error) {
        toast(error.data.error)
      }
    }
  }, [bookVendor, date, discountPrice, selectedCurrency?.code, time])

  const openWhatsappLink = useCallback(() => {
    const whatsappLink = makeWhatsappURL(activity, city, bookVendor, vendors.length <= 0)
    window.open(whatsappLink, '_blank')
  }, [activity, bookVendor, city, vendors.length])

  const requestBookNow = useCallback(
    async (action: string) => {
      if (action === BookingStep.ContactUs) {
        openWhatsappLink()
      } else if (action === BookingStep.SelectDateTime) {
        setShowCalendar(true)
      } else if (action === BookingStep.BookNow) {
        await requestBook()
      } else if (action === BookingStep.Continue) {
        continueNext()
      }
    },
    [openWhatsappLink, setShowCalendar, requestBook, continueNext],
  )

  const btnAction: { action: BookingStep; text: string } = useMemo(() => {
    let action = { action: BookingStep.Continue, text: 'Continue' }
    if (!vendors.length) {
      action = { action: BookingStep.ContactUs, text: 'Contact us' }
    } else if (selectDateTime && !showBookNow) {
      action = { action: BookingStep.SelectDateTime, text: 'Select Date and Time' }
    } else if (bookVendor && showBookNow) {
      action = { action: BookingStep.BookNow, text: 'Book now' }
    }
    return action
  }, [vendors.length, selectDateTime, showBookNow, bookVendor])

  return (
    <section className="hero__section is--landing-page pb-28" id="hero__section__main">
      {requesting && <Loading>{'Requesting Booking'} </Loading>}

      {loadingCurrency && <Loading>{'Loading...'}</Loading>}

      <BookSummary
        vendor={selectedVendor}
        selectVendor={selectVendor}
        setAreaDropdownVisible={setAreaDropdownVisible}
        loading={loading}
      />
      {!loading && showCalendar && <Calendar />}
      {!loading && !showCalendar && (
        <div className={`block w-full bg-white fixed inset-x-0 bottom-0 top-auto z-10 ${cnScroll}`}>
          <div className="flex flex-col relative">
            <div className="absolute inset-0 z-20 flex items-center justify-center px-2.5">
              <Button
                size="large"
                type={vendors.length ? 'dark' : 'normal'}
                onClick={() => requestBookNow(btnAction.action)}
                disabled={false}
              >
                {btnAction.text}
              </Button>
            </div>
            {cityImg ? (
              <AdaptiveImage file={cityImg} alt="" className="hero__image__city" />
            ) : (
              <div className="hero__image__city" />
            )}
          </div>
        </div>
      )}
      {areaDropdownVisible && (
        <AreaDropdown
          city={city}
          onConfirm={() => setAreaDropdownVisible(false)}
          onClose={() => setAreaDropdownVisible(false)}
        />
      )}
    </section>
  )
}

export default HeroActivity
