import React from 'react'

import AdaptiveImage from 'features/common/components/AdaptiveImage'
import useCity from 'features/common/helpers/useCity'
import { Vendor } from 'features/common/types'
import { useBookingService } from 'slices/booking/hooks'
import { useDiscountBarService } from 'slices/discountBar/hooks'

export interface SearchVendorsProps {
  vendor: Vendor | undefined
  selectVendor: (vendor: Vendor) => void
  setAreaDropdownVisible: (value: boolean) => void
}

const SearchVendors = ({ selectVendor, vendor, setAreaDropdownVisible }: SearchVendorsProps) => {
  const city = useCity()

  const {
    booking: { cityImg, vendors, vendorsLimit },
  } = useBookingService()

  const {
    booking: { selectedLocations, locations },
    setSelectedLocations,
  } = useBookingService()
  const {
    discountBar: { discountPrice, discounted },
    setDiscounted,
  } = useDiscountBarService()

  const rVendors = vendors.slice(0, vendorsLimit)

  return (
    <>
      {discounted && (
        <div className="bg-black mt-5 mx-5 mb-0 py-1.5 px-2.5 text-white flex items-center justify-between">
          <div className="non-italic font-normal text-sm">
            20% discount code &ldquo;WELCOME20&ldquo; applied
          </div>
          <div className="w-4 h-4 -mt-1.5" onClick={() => setDiscounted(false)}>
            <img src={'/static/images/header__close-white.svg'} loading="lazy" alt="" />
          </div>
        </div>
      )}
      <div
        className="bg-neutral-100 mt-0 mx-5 mb-2.5 p-2.5 flex items-center"
        id="hero__vendors__location"
      >
        {cityImg && <AdaptiveImage file={cityImg} alt="" className="w-12 h-12 rounded-md" />}
        <div className="flex-1 my-0 mx-2.5 text-left">
          <span className="mb-1.5 text-sm text-black not-italic">Options for:</span>
          <br />
          <span className="text-lg font-semibold text-black not-italic">{city}</span>
        </div>
        <span className="text-sm text-black not-italic flex items-end">Sorted by price</span>
      </div>
      <div
        className="bg-neutral-100 border-0 mt-0 mx-5 mb-2.5 p-2.5 flex items-center"
        onClick={() => setAreaDropdownVisible(true)}
      >
        <span className="min-w-[110px] text-lg font-semibold text-teal-400 italic text-left">
          Area:
        </span>
        <span className="text-lg text-gray-900 not-italic text-left">
          {selectedLocations.length === 0 ? (
            <span className="text-gray-500 italic">
              <b className="font-semibold text-gray-900 not-italic">Select</b> (optional)
            </span>
          ) : selectedLocations.length === locations.length ? (
            'All areas'
          ) : selectedLocations.length === 1 ? (
            <div className="w-36 bg-gray-300 rounded py-1 px-2.5 text-base text-black not-italic flex items-center justify-between">
              <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                {selectedLocations[0].name}
              </span>
              <img
                src={'/static/images/close.svg'}
                alt=""
                className="h-2.5 ml-2"
                onClick={e => {
                  e.stopPropagation()
                  setSelectedLocations(locations)
                }}
              />
            </div>
          ) : (
            `${selectedLocations.length} areas selected`
          )}
        </span>
        <img src={'/static/images/dropdown.svg'} alt="" className="w-5 ml-auto" />
      </div>
      <div className="max-h-80 mt-0 mx-5 mb-2.5 overflow-scroll flex flex-col">
        {rVendors.map((item: Vendor, index: number) => (
          <div
            key={index}
            className="border-b border-solid border-gray-300 mb-2.5 p-2.5 flex items-center justify-between"
            onClick={() => selectVendor(item)}
          >
            <span
              className={`flex-1 max-w-[200px] text-base ${
                item._id === vendor?._id ? 'text-green-600' : 'text-gray-900'
              } not-italic text-left text-ellipsis whitespace-nowrap overflow-hidden`}
            >
              by {item.vendor_name}
            </span>
            {item._id === vendor?._id && (
              <span className="bg-green-600 rounded-sm border border-solid border-cyan-400 box-border my-0 mx-1 p-0.5 text-xs text-white not-italic text-center flex items-center">
                Selected
              </span>
            )}
            <span
              className={`text-base font-semibold ${
                item._id === vendor?._id ? 'text-green-600' : 'text-gray-900'
              } not-italic ${discountPrice ? '!text-red-500 line-through' : ''}`}
            >
              {item.formattedPrice}
            </span>
            {discountPrice && (
              <span className="text-base font-semibold text-gray-900 not-italic">
                &nbsp;{item.discountedPrice}
              </span>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default SearchVendors
