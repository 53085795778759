import React from 'react'

import { SearchProvider } from './searchContext'

export interface AppProviderProps {
  children: React.ReactNode
}

const AppProvider = ({ children }: AppProviderProps) => <SearchProvider>{children}</SearchProvider>

export default AppProvider
