import Bugsnag from '@bugsnag/js'
import axios, { AxiosError, AxiosResponse } from 'axios'

import CONFIG from '../../config'

export default function makeApi(baseURL: string) {
  const api = axios.create({
    baseURL,
  })

  api.defaults.headers.post['Content-Type'] = 'application/json'
  api.defaults.headers.put['Content-Type'] = 'application/json'
  api.defaults.headers.delete['Content-Type'] = 'application/json'

  api.interceptors.request.use(
    config => config,
    error => Promise.reject(error),
  )

  api.interceptors.response.use(
    (response: AxiosResponse) => response.data, // return data object
    (error: AxiosError) => {
      let message = error.response?.statusText
      const object =
        (error.response?.data as { [key: string]: string | string[] | object | object[] }) || {}
      const keys = Object.keys(object)
      if (keys.length > 0) {
        const value = object[keys[0]]
        if (typeof value === 'string') message = `${message}: ${value}`
        if (Array.isArray(value) && value.length > 0) message = `${message}: ${value[0].toString()}`
      }
      const err = {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        message,
      }

      if (!CONFIG.debug && error.response?.status !== 404) {
        Bugsnag.notify(error, event => {
          event.severity = 'info'
          event.context = 'api-client'
        })
      }

      return Promise.reject(err)
    },
  )
  return api
}
