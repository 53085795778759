import React from 'react'

import { useSearch } from 'contexts/searchContext'

const AvailableWorldwide = () => {
  const { setShow } = useSearch()

  const exploreClicked = () => {
    setShow(true)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <section className="body__section brand-main-color">
      <div className="body__container">
        <div className="grid__2 is--2fr-1fr">
          <div className="body__col align-right">
            <h1 className="hero__heading">Available worldwide</h1>
            <h2 className="hero__subheading">
              You can be at a beach or on a boat - wherever you are in the world, with us you can
              book any watersport activity worldwide
            </h2>
          </div>
          <div>
            <div className="search-tile__wrapper">
              <div className="search__tile">
                <img
                  src={'/static/images/plane-take-off.svg'}
                  loading="lazy"
                  alt=""
                  className="search-tile__icon"
                />
                <div className="search-tile__text">Water Toy</div>
              </div>
              <div className="search__tile is--unfocused">
                <img
                  src={'/static/images/hotel-double-bed-1.svg'}
                  loading="lazy"
                  alt=""
                  className="search-tile__icon"
                />
                <div className="search-tile__text">Class</div>
              </div>
              <div className="search__tile is--unfocused">
                <img
                  src={'/static/images/ticket-2.svg'}
                  loading="lazy"
                  alt=""
                  className="search-tile__icon"
                />
                <div className="search-tile__text">Experience</div>
              </div>
            </div>
            <div className="searchbox">
              <div className="searchbox__field">
                <img
                  src={'/static/images/Search.svg'}
                  loading="lazy"
                  alt=""
                  className="searchbox__icon"
                />
                <div>Around current location</div>
                <img
                  src={'/static/images/Microphone.svg'}
                  loading="lazy"
                  alt=""
                  className="searchbox__icon-mic"
                />
              </div>
              <div className="searchbox__field">
                <img
                  src={'/static/images/heart.svg'}
                  loading="lazy"
                  alt=""
                  className="searchbox__icon"
                />
                <div>Seabob, Wakeboard, ...</div>
              </div>
              <div className="searchbox__field">
                <img
                  src={'/static/images/calendar.svg'}
                  loading="lazy"
                  alt=""
                  className="searchbox__icon"
                />
                <div>1 hr - Today, afternoon</div>
              </div>
              <a
                id="btn-available-worldwide-explore"
                className="cta-button search__button w-button"
                onClick={exploreClicked}
              >
                Explore
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AvailableWorldwide
