import moment from 'moment'
import React, { useRef, useState } from 'react'

import { TIME_VALUES } from 'constant'
import Button from 'features/common/components/Button'
import { getDayOfWeek } from 'features/common/helpers/utils'
import { useOutsideClick } from 'libs/hooks'

export interface DateProps {
  onNext: (value: moment.Moment) => void
  outsideClick: () => void
}

const Date = ({ onNext: onNextStep, outsideClick }: DateProps) => {
  const wrapperRef = useRef(null)
  const weekDayShort = moment.weekdaysShort()
  const availableToday = moment(TIME_VALUES[TIME_VALUES.length - 3], 'h:mm').isAfter(moment())

  const [state, setState] = useState({
    showCalendarTable: true,
    showMonthTable: false,
    dateObject: availableToday ? moment() : moment().add(1, 'days'),
    allMonths: moment.months(),
    showYearNav: false,
    showCalendar: false,
    today: moment(),
    todayTomorrow: !availableToday,
    showYearEditor: false,
  })

  useOutsideClick(wrapperRef, outsideClick)

  const getDaysInMonth = () => state.dateObject.daysInMonth()
  const year = () => state.dateObject.format('Y')
  const month = () => state.dateObject.format('MMMM')
  const currentDay = () => state.dateObject.format('D')
  const firstDayOfMonth = () => {
    const { dateObject } = state
    // Day of week 0...1..5...6
    return moment(dateObject).startOf('month').format('d')
  }
  const showMonth = () => {
    setState({
      ...state,
      showMonthTable: !state.showMonthTable,
      showCalendarTable: !state.showCalendarTable,
    })
  }
  const setMonth = (value: string) => {
    const monthNo = state.allMonths.indexOf(value)
    let dateObject = { ...state.dateObject }
    dateObject = moment(dateObject).set('month', monthNo)
    if (moment().format('YYYY-MM') > dateObject.format('YYYY-MM')) return
    setState({
      ...state,
      dateObject,
      showMonthTable: !state.showMonthTable,
      showCalendarTable: !state.showCalendarTable,
    })
  }
  const MonthList = () => {
    const data = moment.months()
    const months: JSX.Element[] = []
    data.forEach(d => {
      months.push(
        <td key={d} className="calendar-month" onClick={() => setMonth(d)}>
          <span>{d}</span>
        </td>,
      )
    })
    const rows: JSX.Element[][] = []
    let cells: JSX.Element[] = []

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
    })
    rows.push(cells)
    const monthList = rows.map((d, i) => <tr key={i}>{d}</tr>)

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan={4}>Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthList}</tbody>
      </table>
    )
  }
  const showYearEditor = () => {
    setState({
      ...state,
      showYearNav: true,
      showCalendarTable: !state.showCalendarTable,
    })
  }
  const setYear = (value: string) => {
    if (moment().format('YYYY') > value) return
    const dateObject = moment(state.dateObject.clone()).set('year', parseInt(value, 10))
    setState({
      ...state,
      dateObject,
      showMonthTable: !state.showMonthTable,
      showYearNav: !state.showYearNav,
    })
  }

  const onPrev = () => {
    let curr: 'year' | 'month'
    if (state.showMonthTable) {
      curr = 'year'
    } else {
      curr = 'month'
    }
    setState({
      ...state,
      dateObject: state.dateObject.clone().subtract(1, curr),
    })
  }
  const onNext = () => {
    let curr: 'year' | 'month'
    if (state.showMonthTable) {
      curr = 'year'
    } else {
      curr = 'month'
    }
    setState({
      ...state,
      dateObject: state.dateObject.clone().add(1, curr),
    })
  }
  const getDates = (startDate: string, stopDate: string) => {
    const dateArray = []
    let currentDate = moment(startDate)
    const newStopDate = moment(stopDate)
    while (currentDate <= newStopDate) {
      dateArray.push(moment(currentDate).format('YYYY'))
      currentDate = moment(currentDate).add(1, 'year')
    }
    return dateArray
  }
  const YearTable = () => {
    const years = year()
    const months: JSX.Element[] = []
    const nextTen = moment().set('year', parseInt(years, 10)).add(12, 'year').format('Y')

    const tenYear = getDates(years, nextTen)

    tenYear.forEach(data => {
      months.push(
        <td key={data} className="calendar-month" onClick={() => setYear(data)}>
          <span>{data}</span>
        </td>,
      )
    })
    const rows: JSX.Element[][] = []
    let cells: JSX.Element[] = []

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
    })
    rows.push(cells)
    const yearList = rows.map((d, i) => <tr key={i}>{d}</tr>)

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan={4}>Select a Yeah</th>
          </tr>
        </thead>
        <tbody>{yearList}</tbody>
      </table>
    )
  }
  const onDayClick = (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, d: number) => {
    let dateObject = { ...state.dateObject }
    dateObject = moment(dateObject).set('date', d)
    if (moment().format('YYYY-MM-DD') > dateObject.format('YYYY-MM-DD')) return
    if (!availableToday && moment().format('YYYY-MM-DD') === dateObject.format('YYYY-MM-DD')) return
    setState({
      ...state,
      dateObject,
    })
  }

  const weekdayshortname = weekDayShort.map(day => <th key={day}>{day[0]}</th>)
  const blanksFirst = []
  let endDayOfLastMonth =
    Number(state.dateObject.clone().subtract(1, 'months').endOf('month').format('DD')) -
    Number(firstDayOfMonth())
  for (let i = 0; i < Number(firstDayOfMonth()); i += 1) {
    endDayOfLastMonth += 1
    blanksFirst.push(
      <td className="calendar-day empty" key={`${i}first`}>
        {endDayOfLastMonth}
      </td>,
    )
  }

  const blanksLast = []
  const firstDayOfNextMonth = state.dateObject.clone().add(1, 'months').startOf('month').format('d')
  let dayOfMonthLast = 1
  for (let i = 7; i > Number(firstDayOfNextMonth); i -= 1) {
    blanksLast.push(
      <td className="calendar-day empty" key={`${i}last`}>
        {dayOfMonthLast}
      </td>,
    )
    dayOfMonthLast += 1
  }

  const daysInMonth = []
  for (let d = 1; d <= getDaysInMonth(); d += 1) {
    let newCurrentDay = ''
    newCurrentDay = d === Number(currentDay()) ? 'today' : ''
    if (
      state.today.clone().format('Y') > year() ||
      (state.today.clone().format('Y') === year() &&
        Number(state.today.clone().format('M')) > Number(state.dateObject.clone().format('M'))) ||
      (state.today.clone().format('Y') === year() &&
        state.today.clone().format('MMMM') === month() &&
        Number(state.today.clone().format('D')) > d)
    ) {
      newCurrentDay = 'empty'
    }
    daysInMonth.push(
      <td key={d} onClick={e => onDayClick(e, d)} className={`calendar-day ${newCurrentDay}`}>
        <span> {d} </span>
      </td>,
    )
  }
  const totalSlots = [...blanksFirst, ...daysInMonth, ...blanksLast]
  const rows: JSX.Element[][] = []
  let cells: JSX.Element[] = []

  totalSlots.forEach((row: JSX.Element, i) => {
    if (i % 7 !== 0) {
      cells.push(row)
    } else {
      rows.push(cells)
      cells = []
      cells.push(row)
    }
    if (i === totalSlots.length - 1) {
      rows.push(cells)
    }
  })

  const daysinmonth = rows.map((d, i) => <tr key={i}>{d}</tr>)

  return (
    <div
      className="w-full max-w-xl fixed z-[990] bg-white p-4 bottom-0 not-italic"
      ref={wrapperRef}
    >
      <span className="text-2xl">{'Select date and time'}</span>
      {state.showCalendar ? (
        <>
          <div className="my-2.5">
            <Button disabled={false} size="large" custom="!text-lg" onClick={() => {}}>
              {getDayOfWeek(state.dateObject.clone())}, {state.dateObject.clone().format('DD MMMM')}
            </Button>
          </div>

          <div className="tail-datetime-calendar">
            <div className="calendar-navi">
              {!state.showMonthTable && !state.showYearEditor && (
                <span onClick={showMonth} className="calendar-label">
                  <b>{month()}&nbsp;</b>
                </span>
              )}
              <span className="calendar-label" onClick={showYearEditor}>
                <b>{year()}</b>
              </span>

              <span onClick={onNext} className="calendar-button-next">
                &#62;
              </span>
              <span onClick={onPrev} className="calendar-button-prev">
                &#60;
              </span>
            </div>
            <div className="calendar-date">
              {state.showYearNav && <YearTable />}
              {state.showMonthTable && <MonthList />}
            </div>

            {state.showCalendarTable && (
              <div className="calendar-date">
                <table className="calendar-day">
                  <thead>
                    <tr>{weekdayshortname}</tr>
                  </thead>
                  <tbody>{daysinmonth}</tbody>
                </table>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {availableToday && (
            <div className="my-2.5">
              <Button
                disabled={false}
                size="large"
                type={!state.todayTomorrow ? 'normal' : 'light'}
                custom={
                  !state.todayTomorrow
                    ? '!text-lg'
                    : 'border border-solid border-neutral-800 !text-lg !font-normal !text-neutral-800'
                }
                onClick={() => {
                  setState({
                    ...state,
                    todayTomorrow: false,
                    dateObject: state.today.clone(),
                  })
                }}
              >
                {getDayOfWeek(state.today.clone())}, {state.today.clone().format('DD MMMM')}
              </Button>
            </div>
          )}
          <div className="my-2.5">
            <Button
              disabled={false}
              size="large"
              type={state.todayTomorrow ? 'normal' : 'light'}
              custom={
                state.todayTomorrow
                  ? '!text-lg'
                  : 'border border-solid border-neutral-800 !text-lg !font-normal !text-neutral-800'
              }
              onClick={() => {
                setState({
                  ...state,
                  todayTomorrow: true,
                  dateObject: state.today.clone().add(1, 'days'),
                })
              }}
            >
              {getDayOfWeek(state.today.clone().add(1, 'days'))},{' '}
              {state.today.clone().add(1, 'days').format('DD MMMM')}
            </Button>
          </div>
        </>
      )}
      {!state.showCalendar && (
        <div className="my-2.5">
          <Button
            disabled={false}
            size="large"
            type="light"
            custom="border border-solid border-neutral-800 !text-lg !font-normal !text-neutral-800"
            onClick={() => setState({ ...state, showCalendar: true })}
          >
            {'Later'}
          </Button>
        </div>
      )}
      <div className="flex mt-8 mr-0 mb-2.5">
        <Button
          disabled={false}
          type="dark"
          size="large"
          onClick={() => onNextStep(state.dateObject.clone())}
        >
          <i>{'Next'}</i>
        </Button>
      </div>
    </div>
  )
}

export default Date
