import parse from 'html-react-parser'
import React from 'react'

import Footer from 'features/common/components/layouts/Footer'
import Header from 'features/common/components/layouts/Header'

import { PRIVACY_POLICY_ITEMS } from '../constant'

const PrivacyPolicyPage = () => (
  <div className="flex flex-col">
    <Header />
    <div className="flex flex-col py-24 px-20 lg:py-10 lg:px-8">
      <span className="mb-28 text-7xl font-semibold text-teal-500 uppercase lg:mb-16 lg:text-3xl">
        Privacy Policy
      </span>
      <span className="mb-20 text-lg font-medium text-black">
        Private Policy Service Watersports Everywhere
        <br />
        V0.1 - 25th of April 2022
        <br />
        ADVANCED BOAT BOOKING AND MORE LTD
        <br />
        11 Staple Inn, London, United Kingdom, WC1V 7QH
        <br />
        privacy@watersports-everywhere.com
      </span>
      <span className="text-lg text-black">
        The following information is provided in compliance with art. 13 of Regulation 2016/679
        (GDPR) to users who access the application and relate to all personal data processed in the
        manner indicated below.
        <br />
        <br />
        Please read our Privacy Policy carefully as it explains the following:
        <br />
        <br />
        <ol>
          <li>
            What personal information is collected from you and for which purpose (Section 2 and 3)
          </li>
          <li>
            The measures to safeguard the access and the security of your Personal Data (Section 4)
          </li>
          <li>
            Your rights to access, rectify, erase your Personal Data, and object to the processing
            of your Personal Data (Section 9).
          </li>
        </ol>
        <br />
        <i>
          To use the Service, you must first acknowledge and agree to our Privacy Policy. As a
          result, you cannot use the Service without first accepting our Privacy Policy.
        </i>
      </span>
      <div className="flex flex-col mt-5">
        {PRIVACY_POLICY_ITEMS.map((privacyItem, index) => (
          <div className="mb-4 text-lg text-black flex flex-col" key={index}>
            <b>
              {index + 1}. {privacyItem.title}
            </b>
            <span>{parse(privacyItem.content)}</span>
          </div>
        ))}
      </div>
    </div>
    <Footer />
  </div>
)

export default PrivacyPolicyPage
