import React from 'react'

import { useSearch } from 'contexts/searchContext'
import { useDiscountBarService } from 'slices/discountBar/hooks'

const DiscountBar = () => {
  const {
    discountBar: { showDiscount },
    setShowDiscount,
    setDiscountPrice,
    setDiscounted,
  } = useDiscountBarService()
  const { setShow } = useSearch()

  const hideDiscountBar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setShowDiscount(false)
  }

  const discountPrice = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation()
    setDiscountPrice(true)
    setDiscounted(true)
    setShowDiscount(false)
    setShow(true)
  }

  if (!showDiscount) return <div />

  return (
    <div
      className="h-24 bg-[#58B7C87F] py-2 text-gray-900 text-center flex flex-row items-center md:h-auto md:bg-black md:p-2.5 md:text-white md:text-left"
      id="top-nav"
    >
      <div className="w-full max-w-screen-xl mx-auto py-0 px-4 flex items-center justify-center relative md:px-0 md:flex-col md:items-stretch">
        <div className="mr-5 w-4/5">
          {'Use the code "WELCOME20" to get a '}
          <span className="font-bold text-teal-500">{'20 % discount'}</span>
          {' on your first booking | '}
          <span className="underline cursor-pointer" onClick={e => discountPrice(e)}>
            {'Try Now'}
          </span>
        </div>
        <div
          data-w-id="baa569ff-f998-d3e7-8dc0-a12e14cbc766"
          className="w-12 max-w-full h-12 inline-block absolute right-0 cursor-pointer md:w-6 md:h-6 md:top-2.5"
          onClick={e => hideDiscountBar(e)}
        >
          <img
            src={'/static/images/header__icon-close.svg'}
            loading="lazy"
            alt=""
            className="w-12 h-12 ml-auto pr-4 absolute inset-0 lg:pr-2 md:hidden"
          />
          <img
            src={'/static/images/header__close-white.svg'}
            loading="lazy"
            alt=""
            className="hidden ml-auto absolute right-0 lg:pr-2 md:top-1.5 md:text-white md:block"
          />
        </div>
      </div>
    </div>
  )
}

export default DiscountBar
