import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

export interface DiscountBarState {
  showDiscount: boolean
  discountPrice: boolean
  discounted: boolean
}

const initialState: DiscountBarState = {
  showDiscount: true,
  discountPrice: false,
  discounted: false,
}

const slice = createSlice({
  name: 'discountBar',
  initialState,
  reducers: {
    setShowDiscount(state, action) {
      state.showDiscount = action.payload
    },
    setDiscountPrice(state, action) {
      state.discountPrice = action.payload
    },
    setDiscounted(state, action) {
      state.discounted = action.payload
    },
    init() {
      return initialState
    },
  },
})

export const {
  setShowDiscount,
  setDiscountPrice,
  setDiscounted,
  init: discountBarInit,
} = slice.actions

export const selectDiscountBar = (state: RootState) => state.discountBar

export default slice.reducer
