import { Activity, City, Location, Vendor } from 'features/common/types'
import { mainAPI } from 'slices/api'

export const getActivities = (search?: string): Promise<{ activities: Activity[] }> =>
  mainAPI.get('/activity', { params: { search } })

export const getActivityImg = (shortName: string): Promise<{ activities: Activity[] }> =>
  mainAPI.get('/activity', { params: { short_name: shortName } })

export const getCities = (city: string): Promise<{ cities: City[] }> =>
  mainAPI.get('/city', { params: { search: city } })

export const getLocations = (params: {
  locationName?: string
  activityName: string
}): Promise<{ locations: Location[] }> =>
  mainAPI.get('/location', {
    params: { location_name: params.locationName, activity_name: params.activityName },
  })

export const getVendors = (data: {
  city: string
  shortName: string
  selectedLocations: Location[]
  areas: Location[]
}): Promise<Vendor[]> => {
  let url = `/vendor?location_name=${data.city}&short_name=${data.shortName}`
  if (data.selectedLocations.length > 0 && data.selectedLocations.length !== data.areas.length) {
    url += `&location_ids=${data.selectedLocations.map(location => location._id).join(',')}`
  }
  return mainAPI.get(url)
}

export const currencyConversion = (data: {
  base_currency_code: string
  desired_currency_code: string
}): Promise<{ conversion_rate: number }> => mainAPI.post('/currency', data)

export const requestBooking = (
  data: {
    discount_code: string
    location_id: string
    inventory_id: string
    start_time: string
    end_time: string
  },
  locale: string,
  currency: string,
): Promise<{ payment_link: string; booking_id: string }> => {
  const bookingUrl = `/booking?locale=${locale}${currency ? `&currency=${currency}` : ''}`
  return mainAPI.post(bookingUrl, data)
}
