import axios from 'axios'

import { Currency } from 'features/common/types'
import { mainAPI } from 'slices/api'

export const getCurrencies = (location?: string): Promise<{ currencies: Currency[] }> =>
  mainAPI.get('/currency', { params: { location_name: location } })

export const getDefaultCurrency = (location: string): Promise<{ currencies: string[] }> =>
  mainAPI.get('/location/currency', { params: { location_name: location } })

export const getCountry = async (): Promise<{ country: string }> => {
  const { data } = await axios.get('https://ipapi.co/json/')
  return data
}
