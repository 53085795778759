import React from 'react'

import { ReactComponent as ArrowLeftSvg } from './svg/arrow-left.svg'
import { ReactComponent as ArrowRightSvg } from './svg/arrow-right.svg'
import { ReactComponent as CalendarSvg } from './svg/calendar.svg'
import { ReactComponent as ChevronDownSvg } from './svg/chevron-down.svg'
import { ReactComponent as ChevronUpSvg } from './svg/chevron-up.svg'
import { ReactComponent as CopySvg } from './svg/copy.svg'
import { ReactComponent as InfoSvg } from './svg/info.svg'
import { ReactComponent as LocationSvg } from './svg/location.svg'
import { ReactComponent as MailSvg } from './svg/mail.svg'
import { ReactComponent as MapSvg } from './svg/map.svg'
import { ReactComponent as MoreSvg } from './svg/more.svg'
import { ReactComponent as PenSvg } from './svg/pen.svg'
import { ReactComponent as PhoneSvg } from './svg/phone.svg'
import { ReactComponent as PoundSvg } from './svg/pound.svg'
import { ReactComponent as SearchSvg } from './svg/search.svg'

import { ReactComponent } from '*.svg'

const iconsMap: { [key: string]: typeof ReactComponent } = {
  info: InfoSvg,
  'chevron-up': ChevronUpSvg,
  'chevron-down': ChevronDownSvg,
  calendar: CalendarSvg,
  location: LocationSvg,
  pen: PenSvg,
  phone: PhoneSvg,
  pound: PoundSvg,
  'arrow-right': ArrowRightSvg,
  'arrow-left': ArrowLeftSvg,
  more: MoreSvg,
  search: SearchSvg,
  mail: MailSvg,
  map: MapSvg,
  copy: CopySvg,
}

export interface SvgIconProps {
  name: string
  [key: string]: string | number | undefined
  className?: string
}

const SvgIcon = ({ className = '', name, ...others }: SvgIconProps) => {
  const Component = iconsMap[name]

  if (!Component) {
    return <div />
  }

  return React.cloneElement(<Component data-testid="svg-icon" />, {
    className,
    ...others,
  })
}

export default SvgIcon
