import React from 'react'
import { isMobile } from 'react-device-detect'

import AdaptiveImage from 'features/common/components/AdaptiveImage'
import ImageSlider from 'features/common/components/ImageSlider'
import Loading from 'features/common/components/Loading'
import getActivity from 'features/common/helpers/getActivity'
import useCity from 'features/common/helpers/useCity'
import WhatsappURL from 'features/common/helpers/whatsappURL'
import { Vendor } from 'features/common/types'
import { useBookingService } from 'slices/booking/hooks'
import { useBookSummaryService } from 'slices/bookSummary/hooks'

import SearchVendors from './SearchVendors'
import VendorDetails from './VendorDetails'

export interface BookSummaryProps {
  vendor: Vendor | undefined
  selectVendor: (vendor: Vendor) => void
  setAreaDropdownVisible: (value: boolean) => void
  loading: boolean
}

const BookSummary = ({
  vendor,
  selectVendor,
  setAreaDropdownVisible,
  loading,
}: BookSummaryProps) => {
  const activity = getActivity()
  const city = useCity()
  const whatsappUrl = WhatsappURL()

  const {
    bookSummary: { selectDateTime, bookVendor },
  } = useBookSummaryService()
  const {
    booking: { activityImg, vendorsLimit, vendors, selectedLocations },
    setVendorsLimit,
  } = useBookingService()

  return (
    <div className="body__container">
      <div className="hero__grid">
        {!isMobile ? (
          <>
            <div className="hero__text-wrapper">
              <h1 className="hero__heading">
                {activity} in {city}
              </h1>
              <h2 className="hero__subheading">
                Book your {activity} quick and easy.
                <br />
                <br />
                Find the best option for {activity} and book it online at your preferred time.
              </h2>
              <a
                href={whatsappUrl}
                id="btn-hero-activity-search-now"
                target="_blank"
                className="cta-button dark-color is--large w-button"
                rel="noreferrer"
              >
                Book now
              </a>
            </div>
            <div>
              <AdaptiveImage file={activityImg[0]} alt="" className="hero__image" />
            </div>
          </>
        ) : (
          <div className="hero__vendors-wrapper">
            <ImageSlider files={activityImg} />
            <div className="hero__price__description">
              Find the best price for <b>{activity}</b> in <b>{city}</b>:
            </div>
            {loading ? (
              <Loading>
                <div className="mt-2 text-lg">
                  Searching options for:
                  <br />
                  <b>{activity}</b> in <b>{city}</b> ...
                </div>
              </Loading>
            ) : (
              <>
                {selectDateTime || bookVendor ? (
                  <VendorDetails fVendor={bookVendor} selectedLocations={selectedLocations} />
                ) : (
                  <SearchVendors
                    vendor={vendor}
                    selectVendor={selectVendor}
                    setAreaDropdownVisible={setAreaDropdownVisible}
                  />
                )}
                {!bookVendor && vendors.length > vendorsLimit ? (
                  <div
                    className="hero__vendors__more"
                    onClick={() => setVendorsLimit(vendors.length)}
                  >
                    show {vendors.length - vendorsLimit} other options
                  </div>
                ) : (
                  !bookVendor &&
                  vendors.length === 0 && <div className="hero__vendors__more">0 options found</div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BookSummary
