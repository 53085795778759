const GTM_IDS: { [key: string]: string } = {
  // PROD
  'flyboard.w-sports.click/dubai': 'GTM-54ZVWZB',
  'jetski.w-sports.click/dubai': 'GTM-PCBFXBV',
  'jetski.w-sports.click/cayman%20islands': 'GTM-WL788B2',
  'kayak.w-sports.click/tamarindo': 'GTM-PTVJD29',
  'sup.w-sports.click/cayman%20islands': 'GTM-NLMSXX3',
  'sup.w-sports.click/dubai': 'GTM-PPZ5CGV',
  'sup.w-sports.click/tamarindo': 'GTM-WP4ZMVC',
  'surf.w-sports.click/tamarindo': 'GTM-5JV3KP7',
  'bananaride.w-sports.click': 'GTM-PGSPWVJ',
  'diving.w-sports.click': 'GTM-WXC9L9G',
  'jetpack.w-sports.click': 'GTM-K2GTKHZ',
  'kitesurf.w-sports.click': 'GTM-PTVJD29',
  'parasailing.w-sports.click': 'GTM-NW74DCB',
  'wakeboard.w-sports.click': 'GTM-WZRVJFB',
  'waterski.w-sports.click': 'GTM-WKPST32',
  'windsurf.w-sports.click': 'GTM-MWKMGJP',
  'sup.w-sports.click/miami%20beach': 'GTM-N8V4M25',
  'kayak.w-sports.click/miami%20beach': 'GTM-W4BQQ8Z',
  'parasailing.w-sports.click/gold%20coast': 'GTM-N7DPKTT',
  'flyboard.w-sports.click/gold%20coast': 'GTM-P9LXLB9',
  'kayak.w-sports.click/gold%20coast': 'GTM-KZ66LH7',
  'sup.w-sports.click/gold%20coast': 'GTM-5HNKB9F',
  'jetski.w-sports.click/gold%20coast': 'GTM-P4FS8JK',
  'jetski.w-sports.click/rio%20de%20janeiro': 'GTM-PHWHS58',
  'sup.w-sports.click/rio%20de%20janeiro': 'GTM-N69G65C',
  'jetski.w-sports.click/cancun': 'GTM-KZ854CN',
  'sup.w-sports.click/cancun': 'GTM-T2V7GJV',
  'surf.w-sports.click/cancun': 'GTM-TTRLPW6',
  'kitesurf.w-sports.click/cancun': 'GTM-PSJ4KBL',
  'jetski.w-sports.click/fort%20lauderdale': 'GTM-MNNV2ZM',
  'sup.w-sports.click/fort%20lauderdale': 'GTM-TSK9JFP',
  'kayak.w-sports.click/fort%20lauderdale': 'GTM-PDKDPRF',
  'surf.w-sports.click/fort%20lauderdale': 'GTM-KTB35XQ',
  'parasailing.w-sports.click/fort%20lauderdale': 'GTM-TTRNLJS',
  'jetski.w-sports.click/panama%20city': 'GTM-K9TDF5L',
  'kayak.w-sports.click/panama%20city%20beach': 'GTM-PCFG379',
  'sup.w-sports.click/panama%20city%20beach': 'GTM-PSV6N4F',
  'kayak.w-sports.click/los%20angeles': 'GTM-WQ48P85',
  'surf.w-sports.click/los%20angeles': 'GTM-W3PK5KC',
  'sup.w-sports.click/los%20angeles': 'GTM-KVXFM4T',
  
  // 03-14-2023
  'sup.w-sports.click/santa%20monica' : 'GTM-5SS4793',
  'surf.w-sports.click/santa%20monica' : 'GTM-P3CQ49Z',
  'kayak.w-sports.click/redondo' : 'GTM-PWFV92T',
  'kayak.w-sports.click/venice' : 'GTM-MTMR33N',
  'sup.w-sports.click/venice' : ' GTM-T7HCZR8',
  'windsurf.w-sports.click/boa%20vista' : 'GTM-NWGP6GJ',
  'kitesurf.w-sports.click/boa%20vista' : 'GTM-MG6XW3W',
  'sup.w-sports.click/santa%20maria' : 'GTM-KZK7MM6',
  'jetski.w-sports.click/santa%20maria' : 'GTM-5J4DL96',
  'bodyboard.w-sports.click/santa%20maria' : 'GTM-PDP3SNC',
  'kayak.w-sports.click/bridgetown' : 'GTM-W85G26W',
  'surf.w-sports.click/bridgetown' : 'GTM-WQP5NWC',
  'sup.w-sports.click/bridgetown' : 'GTM-KX9BT46',
  'windsurf.w-sports.click/bridgetown':'GTM-MWNB768',
  'jetski.w-sports.click/tenerife':'GTM-NDQGGTR',
  'flyboard.w-sports.click/tenerife':'GTM-NTW2BXC',
  'kitesurf.w-sports.click/tenerife':'GTM-N8KNSD2',
  'parasailing.w-sports.click/tenerife':'GTM-WMBFK3F',
  'sup.w-sports.click/tenerife':'GTM-MLNR929',

  // DEVELOPMENT
  'jetski.development.w-sports.click/dubai': 'GTM-577CPB9',
  'jetski.development.w-sports.click': 'GTM-K96MGJR',
}

const DEFAULT_GTM_ID = 'GTM-NQRCMSM'

export const getGtmId = () => {
  if (!window) {
    return DEFAULT_GTM_ID
  }

  const url = window.location.hostname + window.location.pathname
  const gKey = Object.keys(GTM_IDS).find(key => url.includes(key))

  return gKey ? GTM_IDS[gKey] : DEFAULT_GTM_ID
}
