import React from 'react'
import { Oval } from 'react-loader-spinner'

const Loading: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <div className="flex flex-col justify-center items-center bg-white absolute inset-0 bg-slate-50/90 z-50">
    <div>
      <Oval
        height={56}
        width={56}
        color="#fff"
        visible={true}
        secondaryColor="#00aeef"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    </div>
    <div>{children}</div>
  </div>
)

export default Loading
