import { useCallback } from 'react'

import {
  DiscountBarState,
  selectDiscountBar,
  setDiscounted,
  setDiscountPrice,
  setShowDiscount,
} from 'slices/discountBar/store'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type DiscountBarOperators = {
  discountBar: DiscountBarState
  setShowDiscount: (show: boolean) => void
  setDiscountPrice: (show: boolean) => void
  setDiscounted: (show: boolean) => void
}

export const useDiscountBarService = (): Readonly<DiscountBarOperators> => {
  const dispatch = useAppDispatch()

  return {
    discountBar: useAppSelector(selectDiscountBar),
    setShowDiscount: useCallback((show: boolean) => dispatch(setShowDiscount(show)), [dispatch]),
    setDiscountPrice: useCallback((show: boolean) => dispatch(setDiscountPrice(show)), [dispatch]),
    setDiscounted: useCallback((show: boolean) => dispatch(setDiscounted(show)), [dispatch]),
  }
}

export default useDiscountBarService
