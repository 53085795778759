import { useParams } from 'react-router-dom'

const useCity = () => {
  const { cityName } = useParams()

  if (cityName === undefined || cityName === null) {
    return 'your location'
  }

  // replace all hyphens with spaces
  let name = cityName.replace(/-/g, ' ')

  // capitalize first letter of each word
  name = name.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

  return name
}

export default useCity
