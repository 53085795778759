import React from 'react'

import DiscountBar from 'features/booking/DiscountBar'
import Footer from 'features/common/components/layouts/Footer'
import Header from 'features/common/components/layouts/Header'
import AvailableWorldwide from 'features/landing/AvailableWorldwide'
import Carousel from 'features/landing/Carousel'
import ComingSoon from 'features/landing/ComingSoon'
import Hero from 'features/landing/Hero'

const LandingPage = () => (
  <div className="flex flex-col min-h-[calc(100vh-62px)]">
    <Header />
    <DiscountBar />
    <Hero />
    <Carousel />
    <AvailableWorldwide />
    <ComingSoon />
    <Footer />
  </div>
)

export default LandingPage
