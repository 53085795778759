import React from 'react'

import Footer from 'features/common/components/layouts/Footer'
import Header from 'features/common/components/layouts/Header'

import { FAQ_ITEMS } from '../constant'

const FaqPage = () => (
  <div className="flex flex-col">
    <Header />
    <div className="flex flex-col p-10 lg:p-6">
      <span className="mb-5 text-7xl font-semibold text-black lg:text-3xl">F.A.Q.</span>
      <span className="text-4xl font-semibold text-teal-500 lg:text-2xl">
        Frequently Asked Questions
      </span>
      <div className="flex flex-col mt-20">
        {FAQ_ITEMS.map((faqItem, index) => (
          <div className="mb-5 flex flex-col" key={index}>
            <div className="flex items-center">
              <div className="flex items-center" />
              <span className="text-2xl font-bold text-black">{faqItem.question}</span>
            </div>
            <span className="text-lg font-medium text-black">{faqItem.answer}</span>
          </div>
        ))}
      </div>
    </div>
    <Footer />
  </div>
)

export default FaqPage
