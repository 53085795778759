import React from 'react'

import { useSearch } from 'contexts/searchContext'
import getActivity from 'features/common/helpers/getActivity'
import useCity from 'features/common/helpers/useCity'
import { makeWhatsappURL } from 'features/common/helpers/utils'

const Carousel = () => {
  const city = useCity()
  const activity = getActivity()
  const { setShow } = useSearch()

  const openActivity = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, ac: string) => {
    e.stopPropagation()

    const activityUrl = makeWhatsappURL(ac, city)
    window.open(activityUrl, '_blank')
  }

  const exploreMoreClicked = () => {
    setShow(true)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <section className="body__section">
      <div className="body__container">
        <span className="slide-title">
          Discover other activities
          <br />
          in this location:
        </span>
        <div
          data-delay="4000"
          data-animation="slide"
          className="slider w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="true"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div className="mask slider__mobile-flex w-slider-mask">
            {activity !== 'Jet Ski' ? (
              <div className="slide w-slide cursor-point" onClick={e => openActivity(e, 'Jet Ski')}>
                <img
                  src={'/static/images/activity/ID-29_Jet-Ski-sea-scooter-water-scooter_01.jpg'}
                  loading="lazy"
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Jet Ski</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Wakeboard' ? (
              <div
                className="slide w-slide cursor-point"
                onClick={e => openActivity(e, 'Wakeboard')}
              >
                <img
                  src={'/static/images/activity/ID-30_Wakeboard_01.jpg'}
                  loading="lazy"
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Wakeboard</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Banana ride' ? (
              <div className="slide w-slide cursor-point" onClick={e => openActivity(e, 'Banana')}>
                <img
                  src={'/static/images/activity/ID-8_Banana_01.jpg'}
                  loading="lazy"
                  sizes="(max-width: 479px) 95vw, (max-width: 991px) 28vw, 22vw"
                  srcSet={
                    '/static/images/activity/ID-8_Banana_01-p-500.jpeg 500w, /static/images/activity/ID-8_Banana_01-p-800.jpeg 800w, /static/images/activity/ID-8_Banana_01-p-1080.jpeg 1080w, /static/images/activity/ID-8_Banana_01-p-1600.jpeg 1600w, /static/images/activity/ID-8_Banana_01.jpg 1920w'
                  }
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Banana</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'SUP' ? (
              <div
                className="slide w-slide cursor-point"
                onClick={e => openActivity(e, 'Paddle-board')}
              >
                <img
                  src={'/static/images/activity/ID-1_Standup-Paddleboarding-SUP-paddle-surf_02.jpg'}
                  loading="lazy"
                  sizes="(max-width: 479px) 95vw, (max-width: 991px) 28vw, 22vw"
                  srcSet={
                    '/static/images/activity/ID-1_Standup-Paddleboarding-SUP-paddle-surf_02-p-500.jpeg 500w, /static/images/activity/ID-1_Standup-Paddleboarding-SUP-paddle-surf_02-p-800.jpeg 800w, /static/images/activity/ID-1_Standup-Paddleboarding-SUP-paddle-surf_02-p-1080.jpeg 1080w, /static/images/activity/ID-1_Standup-Paddleboarding-SUP-paddle-surf_02-p-1600.jpeg 1600w, /static/images/activity/ID-1_Standup-Paddleboarding-SUP-paddle-surf_02.jpg 2000w'
                  }
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 10 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Paddle-board</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Jet Surf' ? (
              <div
                className="slide w-slide cursor-point"
                onClick={e => openActivity(e, 'Electric surfboard')}
              >
                <img
                  src={'/static/images/activity/ID-34_JetSurf-Electric_01.jpg'}
                  loading="lazy"
                  sizes="(max-width: 479px) 95vw, (max-width: 991px) 28vw, 22vw"
                  srcSet={
                    '/static/images/activity/ID-34_JetSurf-Electric_01-p-500.jpeg 500w, /static/images/activity/ID-34_JetSurf-Electric_01-p-800.jpeg 800w, /static/images/activity/ID-34_JetSurf-Electric_01-p-1080.jpeg 1080w, /static/images/activity/ID-34_JetSurf-Electric_01.jpg 1100w'
                  }
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 8 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Electric surfboard</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Jet Surf' ? (
              <div
                className="slide w-slide is-hidden-on-mobile cursor-point"
                onClick={e => openActivity(e, 'Wakesurf')}
              >
                <img
                  src={'/static/images/activity/ID-30_Wakeboard_01.jpg'}
                  loading="lazy"
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Wakesurf</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Sofa Ride' ? (
              <div
                className="slide w-slide is-hidden-on-mobile cursor-point"
                onClick={e => openActivity(e, 'Sofa ride')}
              >
                <img
                  src={'/static/images/activity/ID-28_Sofa-Ride_01.jpg'}
                  loading="lazy"
                  sizes="(max-width: 479px) 95vw, (max-width: 991px) 28vw, 22vw"
                  srcSet={
                    '/static/images/activity/ID-28_Sofa-Ride_01-p-1080.jpeg 1080w, /static/images/activity/ID-28_Sofa-Ride_01-p-1600.jpeg 1600w, /static/images/activity/ID-28_Sofa-Ride_01-p-2000.jpeg 2000w, /static/images/activity/ID-28_Sofa-Ride_01.jpg 2048w'
                  }
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Sofa ride</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Waterski' ? (
              <div
                className="slide w-slide is-hidden-on-mobile cursor-point"
                onClick={e => openActivity(e, 'Waterski')}
              >
                <img
                  src={'/static/images/activity/ID-31_WaterSki_01.jpg'}
                  loading="lazy"
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Waterski</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {activity !== 'Diving' ? (
              <div
                className="slide w-slide is-hidden-on-mobile cursor-point"
                onClick={e => openActivity(e, 'Diving')}
              >
                <img
                  src={'/static/images/activity/ID-23_Scuba-Diving-Recreational-Diving_01.png'}
                  loading="lazy"
                  sizes="(max-width: 479px) 95vw, (max-width: 991px) 28vw, 22vw"
                  srcSet={
                    '/static/images/activity/ID-23_Scuba-Diving-Recreational-Diving_01-p-500.png 500w, /static/images/activity/ID-23_Scuba-Diving-Recreational-Diving_01-p-800.png 800w, /static/images/activity/ID-23_Scuba-Diving-Recreational-Diving_01.png 2120w'
                  }
                  alt=""
                  className="slide__image"
                />
                <div
                  className={`slider__text__layout${
                    city !== 'your location' && city.length > 15 ? '' : '__flex'
                  }`}
                >
                  <div className="slider__text">Diving</div>
                  {city !== 'your location' ? (
                    <div className="slider__text location">
                      <img
                        src={'/static/images/ic_map.png'}
                        loading="lazy"
                        alt=""
                        className="slider__image"
                      />
                      {city}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="left-arrow w-slider-arrow-left">
            <div className="icon__left w-icon-slider-left" />
          </div>
          <div className="right-arrow w-slider-arrow-right">
            <div className="icon__right w-icon-slider-right" />
          </div>
          <div className="slide-nav w-slider-nav w-round" />
        </div>
        <div className="body__col align-center">
          <a
            href="#"
            id="btn-carousel-expore-more"
            className="cta-button dark-color is--large w-button"
            onClick={exploreMoreClicked}
          >
            Explore more
          </a>
        </div>
      </div>
    </section>
  )
}

export default Carousel
