import React, { useEffect, useState, useRef, useCallback } from 'react'

import Checkbox from 'features/common/components/Checkbox'
import { Location } from 'features/common/types'
import { useOutsideClick } from 'libs/hooks'
import { useBookingService } from 'slices/booking/hooks'

export interface AreaDropdownProps {
  city: string
  onClose: () => void
  onConfirm: () => void
}

const AreaDropdown = ({ city, onClose, onConfirm }: AreaDropdownProps) => {
  const wrapperRef = useRef(null)
  const {
    booking: { locations, selectedLocations },
    setSelectedLocations,
  } = useBookingService()
  const [selectedAreas, setSelectedAreas] = useState<Location[]>([])
  const [all, setAll] = useState(true)

  useEffect(() => {
    if (selectedAreas.length === locations.length) {
      setAll(true)
    }
    if (selectedAreas.length === 0) {
      setAll(false)
    }
  }, [locations.length, selectedAreas])

  useOutsideClick(wrapperRef, onClose)

  useEffect(() => {
    setSelectedAreas(selectedLocations)
  }, [selectedLocations])

  const isChecked = useCallback(
    (area: string | { _id: string }) => {
      if (area === '') {
        return (selectedAreas.length > 0 && all) || selectedAreas.length === locations.length
      }
      return selectedAreas.some(sA => sA._id === (area as Location)._id)
    },
    [all, locations.length, selectedAreas],
  )

  const selectArea = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, area: Location | string, min_price = 1) => {
      if (min_price) {
        e.stopPropagation()

        if (area === '') {
          if (selectedAreas.length === locations.length) {
            setSelectedAreas([])
            setAll(false)
          } else {
            setSelectedAreas(locations)
            setAll(true)
          }
        } else if (selectedAreas.some((sA: Location) => sA._id === (area as Location)._id)) {
          setSelectedAreas(
            selectedAreas.filter((item: Location) => item._id !== (area as Location)._id),
          )
        } else {
          setSelectedAreas([...selectedAreas, area as Location])
        }
      }
    },
    [locations, selectedAreas],
  )

  const confirmAreas = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      if (selectedAreas.length > 0) {
        setSelectedLocations(selectedAreas)
        onConfirm()
      }
    },
    [onConfirm, selectedAreas, setSelectedLocations],
  )

  const findLowestPrice = () => {
    const prices: number[] = []
    let currency
    locations.forEach((area: Location) => {
      if (area.min_price) prices.push(area.min_price)
      if (area.currency_code) currency = area.currency_code
    })
    const lowestPrice = Math.min(...prices)
    return `${lowestPrice} ${currency}`
  }

  return (
    <div className="area-dropdown-fade">
      <div className="area-dropdown" ref={wrapperRef}>
        <div className="area-dropdown-content">
          <div className="area-group-items">
            <div className="area-group-title">Areas</div>
            <div className="area-group-body" onClick={e => selectArea(e, '')}>
              <div className="area-group-item">
                <Checkbox
                  value="all"
                  checked={isChecked('')}
                  onChange={e => selectArea(e, '')}
                  gray={locations.length !== selectedAreas.length}
                />
                <label className="label-bold">All areas in {city}</label>
              </div>
              <span>{findLowestPrice()}</span>
            </div>
          </div>
          <div className="area-group-items">
            <div className="area-group-title select">Select areas in {city}</div>
            {locations.map((area: Location) => (
              <div
                className="area-group-body"
                key={area._id}
                onClick={e => selectArea(e, area, area.min_price)}
              >
                <div className="area-group-item">
                  <Checkbox
                    value={area._id}
                    checked={isChecked(area)}
                    onChange={e => selectArea(e, area)}
                    gray={all}
                    empty={!area.min_price}
                  />
                  <label className={area.min_price ? '' : 'empty'}>
                    {area.name}
                    {(area.total_vendors || 0) > 0 ? ` (${area.total_vendors})` : ''}
                  </label>
                </div>
                <span>
                  {area.min_price ? `${area.min_price.toFixed(2)} ${area.currency_code}` : ''}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`area-confirm-button ${selectedAreas.length > 0 ? '' : 'disabled'}`}
          onClick={e => confirmAreas(e)}
        >
          Confirm
        </div>
      </div>
    </div>
  )
}

export default AreaDropdown
