import { createSlice } from '@reduxjs/toolkit'

import { Vendor } from 'features/common/types'
import type { RootState } from 'store/store'

export interface BookSummaryState {
  selectDateTime: boolean
  bookVendor: Vendor | undefined
  showCalendar: boolean
  showBookNow: boolean
  date: string | undefined
  time: string | undefined
  step: number
}

const initialState: BookSummaryState = {
  selectDateTime: false,
  bookVendor: undefined,
  showCalendar: false,
  showBookNow: false,
  date: undefined,
  time: undefined,
  step: 1,
}

const slice = createSlice({
  name: 'bookSummary',
  initialState,
  reducers: {
    setSelectDateTime(state, action) {
      state.selectDateTime = action.payload
    },
    setBookVendor(state, action) {
      state.bookVendor = action.payload
    },
    setShowCalendar(state, action) {
      state.showCalendar = action.payload
    },
    setShowBookNow(state, action) {
      state.showBookNow = action.payload
    },
    setDate(state, action) {
      state.date = action.payload
    },
    setTime(state, action) {
      state.time = action.payload
    },
    setStep(state, action) {
      state.step = action.payload
    },
    init() {
      return initialState
    },
  },
})

export const bookSummaryActions = {
  setSelectDateTime: slice.actions.setSelectDateTime,
  setBookVendor: slice.actions.setBookVendor,
  setShowCalendar: slice.actions.setShowCalendar,
  setShowBookNow: slice.actions.setShowBookNow,
  setDate: slice.actions.setDate,
  setTime: slice.actions.setTime,
  setStep: slice.actions.setStep,
  init: slice.actions.init,
}

export const selectBookSummary = (state: RootState) => state.bookSummary

export default slice.reducer
