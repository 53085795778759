import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { CountryToCurrency } from 'constant'
import Currency from 'features/common/components/layouts/Header/Currency'
import Nav from 'features/common/components/layouts/Nav'
import getActivity from 'features/common/helpers/getActivity'
import useCity from 'features/common/helpers/useCity'
import { Currency as CurrencyType } from 'features/common/types'
import { useBookSummaryService } from 'slices/bookSummary/hooks'
import { getCountry } from 'slices/currency/api'
import { useCurrencyService } from 'slices/currency/hooks'

const Header = () => {
  const {
    bookSummary: { selectDateTime },
    bookSummaryInit,
  } = useBookSummaryService()
  const {
    currency: { currencies, defaultCurrencyString },
    getCurrencies,
    setSelectedCurrency,
  } = useCurrencyService()
  const activity = getActivity()
  const city = useCity()

  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [showCurrencyNav, setShowCurrencyNav] = useState<boolean>(false)

  const toggleNavOpen = () => {
    setNavOpen(!navOpen)
  }

  function clickHeader() {
    if (selectDateTime) {
      setNavOpen(false)
    } else {
      window.location.href = 'https://w-sports.click/'
    }
  }

  useEffect(() => {
    getCurrencies()
  }, [city, getCurrencies])

  useEffect(() => {
    const getCurrency = async () => {
      try {
        const { country } = await getCountry()
        const currencyCode = CountryToCurrency[country] || ''
        const newCurrency =
          currencies.find(item => item.code.includes(currencyCode)) || currencies[0]
        setSelectedCurrency(newCurrency)
      } catch (e) {
        setSelectedCurrency(currencies[0])
      }
    }

    getCurrency().then()
  }, [currencies, defaultCurrencyString, setSelectedCurrency])

  return (
    <div
      data-animation="over-right"
      data-collapse="small"
      data-duration="400"
      data-w-id="26679374-b92d-0cad-502b-ee28d67038b3"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="fixed left-0 top-0 right-0 bottom-auto bg-white flex flex-col-reverse w-nav"
    >
      {showCurrencyNav ? <Currency setShowCurrencyNav={setShowCurrencyNav} /> : <></>}
      <div className="w-11/12 max-w-7xl mx-auto py-1.5 px-4 flex items-center justify-between md:w-full md:py-2 w-container">
        <a href="#" className="pl-0 flex items-center w-nav-brand" onClick={clickHeader}>
          {selectDateTime ? (
            <span onClick={() => bookSummaryInit()}>
              <img src={'/static/images/arrow_back_ios.svg'} loading="lazy" alt="" />
            </span>
          ) : (
            <>
              <img
                src={'/static/images/header__waves-logo.svg'}
                loading="lazy"
                alt=""
                className="w-16 h-16 object-contain md:w-8 md:h-8"
              />
              {activity && city !== 'your location' ? (
                <div
                  className={`text-4xl font-medium leading-none italic lg:ml-2.5 lg:text-xl lg:text-gray-900 lg:not-italic ${
                    city.length > 10 ? 'flex flex-col' : ''
                  }`}
                >
                  <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                    {activity}
                  </span>
                  <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                    {' '}
                    in {city}
                  </span>
                </div>
              ) : (
                <div className="text-4xl font-medium leading-none italic lg:ml-2.5 lg:text-xl lg:text-gray-900 lg:not-italic">
                  WATERSPORTS <span className="font-light text-teal-500">everywhere</span>
                </div>
              )}
            </>
          )}
        </a>
        <Nav toggleNavOpen={toggleNavOpen} />
        {isMobile ? (
          <img
            className="w-8 h-8 ml-auto float-right"
            src={'/static/images/currency.svg'}
            onClick={() => setShowCurrencyNav(!showCurrencyNav)}
            alt=""
          />
        ) : (
          ''
        )}

        <div
          className="w-11 h-11 ml-auto p-1.5 text-green-600 md:ml-5 w-nav-button"
          onClick={toggleNavOpen}
        >
          {!navOpen ? (
            <div className="w-8 h-8 m-auto flex flex-col items-end justify-around">
              <div
                data-w-id="ce84b4fd-d330-3809-5fbf-34e7bf6fb3e8"
                className="w-full h-1 bg-black rounded p-0"
              />
              <div
                data-w-id="ce84b4fd-d330-3809-5fbf-34e7bf6fb3e9"
                className="w-full h-1 bg-black rounded p-0"
              />
              <div
                data-w-id="ce84b4fd-d330-3809-5fbf-34e7bf6fb3ea"
                className="w-full h-1 bg-black rounded p-0"
              />
            </div>
          ) : (
            <img
              src={'/static/images/ic_close.png'}
              loading="lazy"
              alt=""
              className="w-6 h-6 my-1.5 mx-auto block"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
