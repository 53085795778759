import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import AppProvider from './contexts/AppProvider'
import './index.css'
import BookingPage from './pages/BookingPage'
import FaqPage from './pages/FaqPage'
import LandingPage from './pages/LandingPage'
import NotFound from './pages/NotFound'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import TermsConditionsPage from './pages/TermsConditionsPage'

const App = () => (
  <BrowserRouter>
    <AppProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<TermsConditionsPage />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/:cityName" element={<BookingPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppProvider>
  </BrowserRouter>
)

export default App
