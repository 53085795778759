import { groupBy } from 'lodash'
import React, { useCallback, useState } from 'react'

import { useSearch } from 'contexts/searchContext'
import SearchSelect, { IOption } from 'features/common/components/SearchSelect'
import { getLocations } from 'slices/booking/api'

import ActivitySelect from '../ActivitySelect'

const Hero = () => {
  const [activity, setActivity] = useState('')
  const [location, setLocation] = useState<string>('')
  const [options, setOptions] = useState<IOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { show, setShow } = useSearch()

  const goToSearch = useCallback(() => {
    if (show) {
      if (!activity || !location) {
        return
      }
      window.location.href = `https://${activity}.w-sports.click/${location}`
    } else {
      setShow(true)
    }
  }, [activity, location, setShow, show])

  const locationSearch = useCallback(
    async (search?: string) => {
      try {
        setLoading(true)
        const { locations } = await getLocations({ locationName: search, activityName: activity })
        setLoading(false)
        const data = groupBy(locations, 'country.id')
        const countryOptions = Object.values(data).map(v => ({
          label: '',
          value: '',
          group: v[0].country?.name as string,
          options: v.map(item => ({
            label: item.name,
            value: item.name,
          })),
        }))
        setOptions(countryOptions)
      } catch (e) {
        setLoading(false)
      }
    },
    [activity],
  )

  const onChangeActivity = useCallback(
    async (value: string) => {
      setActivity(value)
      await locationSearch()
    },
    [locationSearch],
  )

  return (
    <section className="h-auto bg-[url('assets/images/section-b-hero-bg.jpg')] bg-cover mb-5 text-white italic flex items-center overflow-hidden">
      <div className="max-w-6xl mx-auto py-6 px-0 block md:w-full md:py-2">
        <div className="grid auto-cols-fr gap-x-14 gap-y-8 grid-cols-2 lg:gap-x-4 md:text-center md: flex-col-reverse md:items-center md:grid-cols-1">
          <div className="text-center italic flex flex-col items-center justify-center md:items-stretch">
            <h1 className="mb-4 text-6xl font-bold italic md:text-4xl">{'Book anywhere'}</h1>
            <h2 className="mt-5 text-3xl italic md:mt-2.5 md:text-xl">
              {
                'We scan the market for you, and find all available watersport activities worldwide.'
              }
            </h2>
            {show && (
              <>
                <div className="my-9">
                  <ActivitySelect value={activity} onChange={onChangeActivity} />
                </div>
                <div className="mb-9">
                  <SearchSelect
                    label="Location"
                    value={location}
                    options={options}
                    onChange={setLocation}
                    group={true}
                    loading={loading}
                    onSearchChange={locationSearch}
                  />
                </div>
              </>
            )}
            <button
              id="btn-hero-search-now"
              className="h-12 bg-gray-800 rounded my-2 py-2.5 px-6 text-2xl text-white text-center italic flex items-center justify-center hover:bg-gray-800"
              onClick={goToSearch}
            >
              {'Search now'}
            </button>
          </div>
          <div>
            <img src={'/static/images/w-sports.click_hero_image.png'} loading="lazy" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
